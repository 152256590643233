import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router';
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);

const Campaignpush = (props) => {
  const { user } = props;
  const { siteId } = useParams();
  const [campaignId, setCampaign] = useState(null);
  const [campaignData, setCampaignData] = useState(null);
  const [campaignpushData, setCampaignpushData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();

  const [values, setValues] = useState({
    push_date_org: null,
    campaign_id: '',
    push_date: null,
    message_alttitle: '',
    message_title: '',
    message_text: '',
    link_label: '',
    link_url: '',
    push_status: constClass.FLAG.OFF,
  });

  const refreshData = useCallback(async () => {
    //トークンを取得
    const jwt = localStorage.getItem('jwt');

    const campaigndata = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaign/search`, { site_id: siteId }, { headers: { Authorization: `Bearer ${jwt}` } })).data;
    setCampaignData(campaigndata);
    var param = {
      site_id: siteId,
    };
    if (campaignId) {
      param = { ...param, campaign_id: campaignId };
      const campaignpushdata = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaignpush/search`, param, { headers: { Authorization: `Bearer ${jwt}` } })).data;
      setCampaignpushData(campaignpushdata);
    }else{
      setCampaignpushData(null);
    }
    handleResetClick();

  }, [siteId, campaignId]);

  /* 編集ボタン押下 */
  const handleUpdateClick = (data) => {
    const temp = data;

    setValues({ ...temp, push_date_org: new Date(temp.push_date), push_date: new Date(temp.push_date) });
  }

  /* RESETボタン押下 */
  const handleResetClick = () => {
    setValues({
      push_date_org: null,
      campaign_id: '',
      push_date: null,
      message_alttitle: '',
      message_title: '',
      message_text: '',
      link_label: '',
      link_url: '',
      push_status: constClass.FLAG.OFF,
      upd_date: null,
    });

  }

  /* DB更新 */
  const handleRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!data.message_text) {
      window.alert('メッセージを入力してください。');
      return;
    }
    if (data.link_url && !data.link_label) {
      window.alert('リンクラベルを入力してください。');
      return;
    }
    if (moment(data.push_date) < moment()) {
      window.alert('送信時間が過去になっています。');
      return;
    }
    if (data.push_status === constClass.FLAG.ON) {
      if (!window.confirm(`送信済データを修正しています。未送信状態に戻りますが、よろしいですか？`)) {
        return;
      }
    }

    var params = data; console.log(util.inspect(data));
    params.campaign_id = campaignId;
    params.message_alttitle = await campaignData.find(x => x.campaign_id.toString() === campaignId.toString()).campaign_name;
    params.push_status = constClass.FLAG.OFF;
    params.ins_name = user.userId;
    params.upd_name = user.userId;
    params.site_id = siteId;

    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaignpush/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }
  /* DB削除 */
  const handleDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (data.push_status === constClass.FLAG.ON) {
      if (!window.confirm(`送信済データを削除しようとしています。\nキャンペーンID:${data.campaign_id}、配信日時：${moment(data.push_date).format('YYYY/MM/DD HH:mm')}を削除します。\nよろしいですか？`)) {
        return;
      }
    } else
      if (!window.confirm(`キャンペーンID:${data.campaign_id}、配信日時：${moment(data.push_date).format('YYYY/MM/DD HH:mm')}を削除します。よろしいですか？`)) {
        return;
      }
    const params = {
      site_id: siteId,
      campaign_id: data.campaign_id,
      push_date: data.push_date,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaignpush/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      console.log(res);
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }

  const renderUpdateButton = (data) => {
    return (
      <button
        className={`btn btn-primary mx-1`}
        onClick={() => handleUpdateClick(data)}>
        編集
      </button>
    )
  }
  const renderDefaultMessageButton = () => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1 `}
        onClick={() => handleChangeMessageDefault()}>
        デフォルトメッセージセット
      </button>
    )
  }
  const renderDefaultUrlButton = () => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1 `}
        onClick={() => handleChangeUrlDefault()}>
        デフォルトURLセット
      </button>
    )
  }
  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleRegistClick(data)}>
        登録
      </button>
    )
  }

  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || !data.push_date_org}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleDeleteClick(data)}>
        削除
      </button>
    )
  }

  const renderResetButton = () => {
    return (
      <button type="button"
        disabled={false}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleResetClick()}>
        リセット
      </button>
    )
  }

  const handleCampaign = (e) => {
    const target = e.target;
    setCampaign(target.value);
  }

  const handleChangePushdate = (date) => {
    setValues({ ...values, push_date: date ? date : null });
  }
  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setValues({ ...values, [name]: value });
  }
  const handleChangeMessageDefault = () => {
    const value = `「${campaignData.find(x => x.campaign_id.toString() === campaignId.toString()).campaign_name}」実施中！\nお客様の総ポイント数は、##POINT##。現在の応募口数は##ENTRYCOUNT##です。`;
    setValues({ ...values, message_text: value });
  }
  const handleChangeUrlDefault = () => {
    const value = `/${siteId}/?campaign_id=${campaignId}`;
    setValues({ ...values, link_url: value, link_label: '現在のポイントを見る' });
  }
  useEffect(() => {
    refreshData();
  }, [refreshData]); //

  const renderInput = () => {
    return (
      <div className="mx-1">
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>キャンペーンID</span>
          </div>
          <div className="col-5 text-left align-self-center">
            {campaignId && campaignData.find(x => x.campaign_id.toString() === campaignId.toString()).campaign_name}
           {/*  <select className="custom-select w-100" aria-label="キャンペーン" name="campaign_id" defaultValue={campaignId} readOnly>
              <option value="">選択してください</option>
              {campaignData.map((item) =>
                <option key={item.campaign_id} value={item.campaign_id}>{item.campaign_name}</option>
              )}
            </select> */}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="campaignStart">配信日時</label>
          </div>
          <div className="col-5 text-left align-self-center">
            <DatePicker className="custom-select w-50 text-left border-textbox"
              aria_labal="配信日" locale="ja" dateFormat="yyyy/MM/dd HH:mm" monthsShown="1" id="pushDate" name="pushDate"
              popperPlacement='right'
              showTimeSelect
              selected={values.push_date ? values.push_date : null}
              onChange={date => handleChangePushdate(date)}
              placeholderText="- 選択 -"
            />
          </div>
        </div>
        {/* <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="campaignStart">ALTタイトル</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="message_alttitle" value={values.message_alttitle} onChange={handleChange} placeholder="ALTタイトルを入力してください" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="campaignStart">タイトル</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="message_title" value={values.message_title} onChange={handleChange} placeholder="タイトルを入力してください" />
          </div>
        </div>
         */}
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="campaignStart">メッセージ</label>
          </div>
          <div className="col-7 text-center align-self-center">
            <textarea className="form-control" name="message_text" onChange={handleChange} value={values.message_text} />
            <div className="w-100 text-left">##POINT##：ポイント数　##ENTRYCOUNT##：応募口数</div>
          </div>
          <div className="col-3 text-center align-self-center">
            {renderDefaultMessageButton()}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="campaignStart">リンク名</label>
          </div>
          <div className="col-3 text-center align-self-center">
            <input type="text" className="form-control" name="link_label" value={values.link_label} onChange={handleChange} placeholder="リンク名を入力してください" />
          </div>
          <div className="col-1 text-center align-self-center">
            <label htmlFor="campaignStart">リンクURL</label>
          </div>
          <div className="col-3 text-center align-self-center">
            <input type="text" className="form-control" name="link_url" value={values.link_url} onChange={handleChange} placeholder="リンクURLを入力してください" />
          </div>
          <div className="col-3 text-center align-self-center">
            {renderDefaultUrlButton()}
          </div>
        </div>
        <div className="row mb-2 pb-4">
          <div className="col-3 text-center">
            {renderRegistButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderDeleteButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderResetButton(values)}
          </div>

        </div>
      </div>
    );
  }
  const getBrString = (str) => {
    const texts = str.split(/(\n)/).map((item, index) => {
      return (
        <React.Fragment key={index}>
          {item.match(/\n/) ? <br /> : item}
        </React.Fragment>
      );
    });
    return <div>{texts}</div>;
  }
  const renderList = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="col-12 p-0">
          <table className="table table-bordered table-striped">
            <thead className={`table-info`}>
              <tr>
                <td className="text-center align-middle">
                  キャンペーン
                </td>
                <td className="text-center align-middle">
                  配信日
                </td>
                <td className="text-center align-middle">
                  メッセージ
                </td>
                <td className="text-center align-middle">
                  リンク名
                </td>
                <td className="text-center align-middle">
                  リンクURL
                </td>
                <td className="text-center align-middle">
                  送信
                </td>
                <td className="text-center align-middle">
                  処理
                </td>
              </tr>
            </thead>
            <tbody>
              {campaignpushData.map((data, idx) => (
                <tr key={idx}>
                  {/* キャンペーンID */}
                  <td className="text-center align-middle">
                    {data.campaign_id}:{campaignData.find(x => x.campaign_id === data.campaign_id).campaign_name}
                  </td>
                  {/* 配信日時 */}
                  <td className="text-center align-middle">
                    {moment(data.push_date).format('YYYY/MM/DD HH:mm')}
                  </td>
                  {/* メッセージ */}
                  <td className="text-left align-middle">
                    {getBrString(data.message_text)}
                  </td>
                  {/* リンク */}
                  <td className="text-left align-middle">
                    {data.link_label}
                  </td>
                  {/* リンク */}
                  <td className="text-left align-middle text-nowrap">
                    {data.link_url}
                  </td>
                  {/* 送信区分 */}
                  <td className="text-center align-middle">
                    {data.push_status === constClass.PUSH_STATUS.SEND ? '済' : '未'}
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle text-nowrap">
                    {renderUpdateButton(data)}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  const renderHeader = () => {
    return (
      <div className="mx-0">
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>キャンペーン</span>
          </div>
          <div className="col-5 text-center">
            <select className="custom-select w-100" aria-label="キャンペーン" value={campaignId || ''} onChange={handleCampaign}>
              <option value="">選択してください</option>
              {campaignData.map((item) =>
                <option key={item.campaign_id} value={item.campaign_id}>{item.campaign_name}</option>
              )}
            </select>

          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="container">
      {(campaignpushData === null && campaignData === null) && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {campaignData !== null && renderHeader()}
      {(campaignpushData !== null && campaignData !== null) &&
        <div>
          {renderList()}
          {renderInput()}
        </div>
      }
    </div>
  )
}

export default Campaignpush;