import React, { useState } from 'react';
import constClass from '../../Constants/Constants';

const PointDetail = ({match, setMode, appState, setPointRegist}) => {
  const [lockData, setLockData] = useState(false);

  const handleSubmitClick = async () => {
    if (!window.confirm('お支払を確定してよろしいですか？')) {
      return false;
    }
    setLockData(true);
    setPointRegist();
    setLockData(false);
  }

  const handleCloseClick = () => {
    setMode(constClass.POINT_SCREEN.MAIN);
  }
 
  
  return (
    <div>
      <div className="row">
        <div className="col my-3 text-left bg-white">
          <div className="card-header p-2 border">
            <span><b>会員ID:</b></span>
            <span className="float-right pl-2">{appState.membership_id}</span>
          </div>
          <div className="card-header p-2 text-left border">
            <span><b>利用可能ポイント:</b></span>
            <span className="float-right pr-2"><b>{appState.available_point.toLocaleString()}</b> ポイント</span>
          </div>
          <div className="card-header p-2 text-left border">
            <span><b>会員ランク:</b></span>
            <span className="float-right pr-2"><b>{appState.customer_rank ? appState.customer_rank.rank_name : null}</b></span>
          </div>
          <div className="card-header p-2 text-left border">
            <span><b>ポイント還元率:</b></span>
            <span className="float-right pr-2"><b>{appState.customer_rank ? appState.customer_rank.point_rate * 100 : null}</b> ％</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col my-3 text-left bg-white">
          <div className="card-header p-2 border">
            <span><b>対象金額:</b></span>
            <span className='float-right pl-2'>{Number(appState.inputValue).toLocaleString('ja-JP', {style:'currency', currency: 'JPY'})}</span>
          </div>
          <div className="card-header p-2 text-left border">
            <span><b>ご利用ポイント:</b></span>
            <span className="float-right pr-2"><b>{appState.usePoint.toLocaleString()}</b> ポイント</span>
          </div>
          <div className="card-header p-2 border">
            <span><b>お支払金額:</b></span>
            <span className='float-right pl-2'>{appState.paymentValue.toLocaleString('ja-JP', {style:'currency', currency: 'JPY'})}</span>
          </div>
          <div className="card-header p-2 text-left border">
            <span><b>加算ポイント:</b></span>
            <span className="float-right pr-2"><b>{appState.addPoint.toLocaleString()}</b> ポイント</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col my-3 text-left bg-white">
          <div className="card-header p-2 border">
            <span><b>ポイント残高:</b></span>
            <span className='float-right pl-2'><b>{appState.pointBalance.toLocaleString()}</b> ポイント</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col text-center">
          <button
            disabled={lockData}
            className="btn btn-primary w-75"
            onClick={() => handleSubmitClick()}>
            確定
          </button>
        </div>
        <div className="col text-center">
          <button
            disabled={lockData}
            className="btn btn-enable w-75"
            onClick={() => handleCloseClick()}>
            戻る
          </button>
        </div>
      </div>
    </div>
  );
};

export default PointDetail;