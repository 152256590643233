import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Loading from '../Loading/Loading';
import util from 'util';
import QRCode from 'react-qr-code';
import Popover from 'react-popover';

const MODE = {
  LOGIN: null,
  MFA_REG: '1',
  MFA_AUTH: '2',
};
const Login = (props) => {
  const { user, userActions } = props;
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [message, setMessage] = useState(null);
  const [mode, setMode] = useState(null);
  const [authCode, setAuthCode] = useState('');
  const [authCode1, setAuthCode1] = useState('');
  const [authCode2, setAuthCode2] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [secret, setSecret] = useState('');
  const [secretRender, setSecretRender] = useState(false);
  const [userData, setUserData] = useState({});
  const authCodeRef = useRef();
  const siteId = props.match.params.siteId;

  const handlePostThen = (response) => {
    console.log(response);
    if (response.status === 200) {
      var userData = response.data;
      if (userData !== undefined) {
        setMessage(null);
        if (userData.jwt) {
          // jwtが発行されていればログイン成功
          localStorage.setItem('jwt', userData.jwt);
          userActions.login({
            siteId: userData.site_id,
            userId: userData.user_id,
            userName: userData.user_name,
            userShort: userData.user_short,
            userClass: String(userData.user_class),
            userToken: userData.jwt,
            mfa: userData.mfa,
          });
        } else if (userData.secret_count) {
          setMode(MODE.MFA_AUTH);
          if (authCodeRef.current) {
            authCodeRef.current.focus();
          }
        } else {
          setMode(MODE.MFA_REG);
        }
      } else {
        setMessage('ユーザが見つかりません');
        userActions.logout();
      }
    } else {
      setMessage('ユーザが見つかりません');
      userActions.logout();
    }
  }

  const handlePostCatch = (err) => {
    console.log(util.inspect(err));
    setMessage(err.response.data);
    userActions.logout();
  }

  const onSubmit = async (data) => {
    userActions.fetchLogin();
    const user_params = {
      site_id: siteId,
      user_id: data.userId,
      password: data.passWord,
    }
    setUserData(user_params);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/login/`, user_params);
      handlePostThen(response);
    } catch (err) {
      handlePostCatch(err);
    }
  }

  const qrRequest = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/mfa/register`, userData);
      if (response.status === 200) {
        const qr = response.data;
        console.log(qr);
        setQrCode(qr.otpauth_url);
        setSecret(qr.secret);
      }
    } catch (err) {
      console.log(util.inspect(err.response.data));
    }
  }

  const mfaVerify = async () => {
    const params = {
      ...userData,
      authcode1: authCode1,
      authcode2: authCode2
    };
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/mfa/verify`, params);
      if (response.status === 200) {
        var data = response.data;
        if (data !== undefined) {
          console.log(data);
          window.alert('認証に成功しました。');
          localStorage.setItem('jwt', data.jwt);
          userActions.login({
            userId: data.user_id,
            userName: data.user_name,
            userShort: data.user_short,
            userClass: String(data.user_class),
            siteId: data.site_id,
            userToken: data.jwt,
            mfa: data.mfa,
          });
        } else {
          setMessage('認証情報が正しくありません。もう一度試してください。');
          userActions.logout();
        }
      } else {
        setMessage('認証情報が正しくありません。もう一度試してください。');
        userActions.logout();
      }
    } catch (err) {
      console.log(util.inspect(err.response.data));
      setMessage(err.response.data);
      userActions.logout();
    }
  }

  const mfaLogin = async () => {
    const params = {
      ...userData,
      authcode: authCode
    };
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/mfa/login`, params);
      if (response.status === 200) {
        var data = response.data;
        if (data !== undefined) {
          console.log(data);
          localStorage.setItem('jwt', data.jwt);
          userActions.login({
            userId: data.user_id,
            userName: data.user_name,
            userShort: data.user_short,
            userClass: String(data.user_class),
            siteId: data.site_id,
            userToken: data.jwt,
            mfa: data.mfa,
          });
        } else {
          setMessage('認証情報が正しくありません。もう一度試してください。');
          userActions.logout();
          setMode(MODE.LOGIN);
          setAuthCode('');
        }
      } else {
        setMessage('認証情報が正しくありません。もう一度試してください。');
        userActions.logout();
        setMode(MODE.LOGIN);
        setAuthCode('');
      }
    } catch (err) {
      console.log(util.inspect(err.response.data));
      setMessage('認証情報が正しくありません。もう一度試してください。');
      userActions.logout();
      setMode(MODE.LOGIN);
      setAuthCode('');
    }
  }

  const handleKeyDown = (func) => (e) => {
    if (e.nativeEvent.isComposing || e.key !== 'Enter') return;
    func()
  }

  const renderSubmit = () => {
    return user.isFetching ? <Loading /> : <input type="submit" value="ログイン" />;
  }

  return (
    <div className='container'>
      <div className='row m-2'>
        <div className="col text-center">
          <h3> ログイン </h3>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={mode === MODE.LOGIN ? '' : 'd-none'}>
        <div className="row m-2">
          <div className="col text-center">
            <input name="userId" placeholder="ユーザID" {...register('userId', { required: true })} />
          </div>
        </div>
        <div className="row m-2">
          <div className="col text-center">
            <input type="password" name="passWord" placeholder="パスワード" {...register('passWord', { required: true })} />
          </div>
        </div>
        {errors.userId && <div className="row m-2">
          <div className="col text-center">
            <span className="text-danger">ユーザIDは必須項目です</span>
          </div>
        </div>}
        {errors.passWord && <div className="row m-2">
          <div className="col text-center">
            <span className="text-danger">パスワードは必須項目です</span>
          </div>
        </div>}
        {message && <div className="row m-2">
          <div className="col text-center">
            <span className="text-danger">{message}</span>
          </div>
        </div>}
        <div className="row m-2">
          <div className="col text-center">
            {renderSubmit()}
          </div>
        </div>
      </form>
      {mode === MODE.MFA_AUTH &&
        <React.Fragment>
          <div className="row m-2">
            <div className="col text-center">
              <h4 className="mb-0">二要素認証</h4>
            </div>
          </div>
          <div className="row m-2 mb-3">
            <div className="col text-center">
              MFAコードを入力してください。
            </div>
          </div>
          <div className="row m-2">
            <div className="col text-center">
              <label htmlFor='authCode'>MFAコード</label>
              <input type="text" className="ml-1" name="authCode" placeholder="" value={authCode} onChange={(e) => setAuthCode(e.target.value)} onKeyDown={handleKeyDown(mfaLogin)} ref={authCodeRef} />
            </div>
          </div>
          <div className="row m-2">
            <div className="col text-center">
              <button className="btn btn-enable" onClick={() => mfaLogin()}>送信</button>
            </div>
          </div>
          {message && <div className="row m-2">
            <div className="col text-center">
              <span className="text-danger">{message}</span>
            </div>
          </div>}
        </React.Fragment>
      }
      {mode === MODE.MFA_REG &&
        <React.Fragment>
          <div className="row m-2">
            <div className="col text-center">
              <h4 className="mb-0">二要素認証を設定してください。</h4>
            </div>
          </div>
          <div className="row m-2">
            <div className="col text-center">
              セキュリティ向上のため、二要素認証が必要となります。以下の手順で設定をお願いいたします。
            </div>
          </div>
          <div className="row m-2">
            <div className="col text-left">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      &#9312;Google Authenticator Duo Mobile や Authy アプリなどの互換性のあるアプリケーションをモバイルデバイスまたはコンピュータにインストールします。
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="row mb-2">
                        <div className="col">
                          &#9313;認証アプリを開き、このページで [二次元バーコードを表示] を選択し、アプリケーションを使用してコードをスキャンします。
                        </div>
                      </div>
                      <div className="row text-center">
                        <div className="col"></div>
                        <div className="col-auto">
                          {!qrCode &&
                            <button className="btn btn-enable" onClick={() => qrRequest()}>二次元バーコードを表示</button>
                          }
                          {qrCode &&
                            <QRCode value={`${qrCode}`} />
                          }
                          {secret &&
                            <React.Fragment>
                              <br />
                              <span>二次元バーコードが読み取れない場合は、
                                <Popover
                                  body={<span className="bg-white border p-1 shadow">{secret}</span>}
                                  place='below'
                                  isOpen={secretRender}>
                                  <span className="text-info" onMouseEnter={() => setSecretRender(true)} onMouseLeave={() => setSecretRender(false)}><u>セットアップキー</u></span>
                                </Popover>
                                を入力してください。</span>
                            </React.Fragment>
                          }
                        </div>
                        <div className="col"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="row mb-2">
                        <div className="col">
                          &#9314;MFA デバイスから 2 つの連続したコードを入力して再同期します。
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label htmlFor='authCode1'>MFAコード1</label>
                          <input type="text" className="ml-1" name="authCode1" placeholder="" value={authCode1} onChange={(e) => setAuthCode1(e.target.value)} onKeyDown={handleKeyDown(mfaVerify)} />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col">
                          <label htmlFor='authCode2'>MFAコード2</label>
                          <input type="text" className="ml-1" name="authCode2" placeholder="" value={authCode2} onChange={(e) => setAuthCode2(e.target.value)} onKeyDown={handleKeyDown(mfaVerify)} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">
                          <button className="btn btn-enable" onClick={() => mfaVerify()}>送信</button>
                        </div>
                      </div>
                      {message &&
                        <div className="row mt-2">
                          <div className="col text-center">
                            <span className="text-danger">{message}</span>
                          </div>
                        </div>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </React.Fragment>
      }
    </div>
  );
}

export default Login;