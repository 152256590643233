import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
// import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import { generatePath, useParams } from 'react-router';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import Select from 'react-select';
import Common from '../Common/common';

const Campaign = (props) => {
  const { user, setting } = props;
  const { siteId } = useParams();
  const [shopMaster, setShopMaster] = useState([]);
  const [campaignData, setCampaignData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();
  const history = useHistory();

  const [values, setValues] = useState({
    campaign_id: null,
    campaign_name: '',
    campaign_end_enquete: constClass.FLAG.OFF,
    privacy_policy_url: '',
    campaign_start: null,
    campaign_end: null,
    campaign_open: null,
    campaign_close: null,
    point_name: '',
    entry_count: 0,
    entry_header: '',
    entry_content: '',
    entry_end: '',
    uploadfile: null,
    uploadfiletype: null,
    deletefile: null
  });
  const [campaignShop, setCampaignShopValues] = useState([]);

  const refreshData = useCallback(async () => {
    //トークンを取得
    const jwt = localStorage.getItem('jwt');

    const shop = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/shop/${siteId}`, { headers: { Authorization: `Bearer ${jwt}` } })).data.map((val) => { return { value: val.shop_id, label: val.shop_name }; });
    setShopMaster(shop);

    const campaignData = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaign/search`, { site_id: siteId }, { headers: { Authorization: `Bearer ${jwt}` } })).data;
    setCampaignData(campaignData);
  }, [siteId]);

  /* 編集ボタン押下 */
  const handleUpdateClick = (data) => {
    const temp = data;
    temp.deletefile = null;
    temp.uploadfiletype = null;
    if (data.campaign_image_url != null) temp.uploadfile = `/${siteId}/${constClass.CAMPAIGN_IMAGE_DIR + data.campaign_image_url}`;
    else temp.uploadfile = null;
    setValues(temp);
    var data_shop = data.campaign_shop.map((a) => {
      return { value: a.shop_id, label: a.shop_name };
    });
    setCampaignShopValues(data_shop);

  }

  /* RESETボタン押下 */
  const handleResetClick = () => {
    setValues({
      campaign_id: null,
      campaign_name: '',
      campaign_end_enquete: constClass.FLAG.OFF,
      privacy_policy_url: '',
      campaign_start: null,
      campaign_end: null,
      campaign_open: null,
      campaign_close: null,
      point_name: '',
      entry_count: 0,
      entry_header: '',
      entry_content: '',
      entry_end: '',
      uploadfile: null,
      uploadfiletype: null,
      deletefile: null
    });
    setCampaignShopValues([])
  }

  /* アイコン管理画面へ遷移 */
  const handleIconClick = (campaign_id) => {
    history.push(`${generatePath(`${props.match.path}campaignicon/${campaign_id}`, { siteId })}`);
  }

  /* DB更新 */
  const handleRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.campaign_name) {
      window.alert('キャンペーン名を入力してください。');
      return;
    }
    if (!data.campaign_start || !data.campaign_end) {
      window.alert('キャンペーン期間を入力してください。');
      return;
    }
    if (!data.campaign_open || !data.campaign_close) {
      window.alert('キャンペーン公開期間を入力してください。');
      return;
    }
    if (moment(data.campaign_start) > moment(data.campaign_end)) {
      window.alert('キャンペーン期間が間違っています。');
      return;
    }
    if (moment(data.campaign_open) > moment(data.campaign_close)) {
      window.alert('キャンペーン公開期間が間違っています。');
      return;
    }
    if (moment(data.campaign_start) < moment(data.campaign_open) || moment(data.campaign_end) > moment(data.campaign_close)) {
      if (!window.confirm(`キャンペーン期間内に公開期間が設定されています。よろしいですか？`)) {
        return;
      }
    } else {
      if (!window.confirm(`登録してよろしいですか？`)) {
        return;
      }
    }
    var params = data;
    if (data.uploadfiletype === null) data.uploadfile = null;
    params.ins_name = user.userId;
    params.upd_name = user.userId;
    params.site_id = siteId;
    params.campaign_shop = campaignShop.map(shop => ({ site_id: siteId, campaign_id: data.campaign_id, shop_id: shop.value }));

    let res;
    try {
      setLockData(true);
      if (params.campaign_id === null || params.campaign_id === "") {
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaign/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      } else {
        res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/campaign/${params.site_id}/${params.campaign_id}`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }
  /* DB削除 */
  const handleDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`キャンペーン[${data.campaign_id}:${data.campaign_name}]を削除します。\n関連データ(アイコン・通知・お客様のエントリー情報・ポイント情報）もあわせて削除されます。\nよろしいですか？`)) {
      return;
    }
    const params = {
      site_id: siteId,
      campaign_id: data.campaign_id,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaign/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }
  const renderIconButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.campaign_id === null}
        className={`btn btn-primary mx-1`}
        onClick={() => handleIconClick(data.campaign_id)}>
        アイコン管理
      </button>
    )
  }
  const renderUpdateButton = (data) => {
    return (
      <button
        className={`btn btn-primary mx-1`}
        onClick={() => handleUpdateClick(data)}>
        編集
      </button>
    )
  }

  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.campaign_name === ''}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleRegistClick(data)}>
        登録
      </button>
    )
  }

  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.campaign_id === null}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleDeleteClick(data)}>
        削除
      </button>
    )
  }

  const renderResetButton = () => {
    return (
      <button type="button"
        disabled={false}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleResetClick()}>
        リセット
      </button>
    )
  }

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setValues({ ...values, [name]: value });
  }
  const handleChangeDate = (name, value) => {
    var temp = { ...values, [name]: value }
    if (!Common.getFunctionEnabled(setting, 'CAMPAIGN_LIST')) {
      // 一覧表示しない場合は公開開始日と開始日・公開終了日と終了日を合わせる
      if (name === 'campaign_open') {
        temp = { ...temp, 'campaign_start': value };
      } else if (name === 'campaign_close') {
        temp = { ...temp, 'campaign_end': value };
      }
    }
    setValues(temp);
  }
  const handleChangeShop = (data) => {
    setCampaignShopValues(data);
  }

  useEffect(() => {
    refreshData();
  }, [refreshData]); //

  const renderInput = () => {
    return (
      <div className="mx-1">
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>キャンペーンID</span>
          </div>
          <div className="col-2 text-center align-self-center">
            <input type="text" className="form-control" name="campaign_id" defaultValue={values.campaign_id} placeholder="新規" readOnly />
          </div>
        </div>
        {values.campaign_id &&
          <React.Fragment>
            <div className="row mb-1">
              <div className="col-2 text-center align-self-center">
                <span>キャンペーン説明URL</span>
              </div>
              <div className="col-10 text-left align-self-center">
                {`${setting['LINE_LIFF_URL']}?page=${constClass.CONFIRM}&campaign_id=${values.campaign_id}`}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-2 text-center align-self-center">
                <span>キャンペーン登録URL</span>
              </div>
              <div className="col-10 text-left align-self-center">
                {`${setting['LINE_LIFF_URL']}?page=${constClass.CAMPAIGNENTRY}&campaign_id=${values.campaign_id}`}
              </div>
            </div>
          </React.Fragment>
        }
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>キャンペーン名</span>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="campaign_name" value={values.campaign_name} onChange={handleChange} placeholder="キャンペーン名を入力してください" />
          </div>
        </div>
        {Common.getFunctionEnabled(setting, 'CAMPAIGN_POINT') &&
          <React.Fragment>
            <div className="row mb-3">
              <div className="col-2 text-center align-self-center">
                <span>応募ポイント数</span>
              </div>
              <div className="col-2 text-center align-self-center">
                <input type="text" className="form-control" name="entry_count" value={values.entry_count} onChange={handleChange} placeholder="応募ポイント数を入力してください" />
              </div>
              <div className="col-2 text-center align-self-center">
                <span>ポイント名称</span>
              </div>
              <div className="col-3 text-center align-self-center">
                <input type="text" className="form-control" name="point_name" value={values.point_name} onChange={handleChange} placeholder="ポイント名称を入力してください" />
              </div>
            </div>
          </React.Fragment>
        }
        {/* <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>終了後アンケート</span>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="checkbox" className="form-control" name="campaign_end_enquete" value={constClass.FLAG.ON} checked={values.campaign_end_enquete === constClass.FLAG.ON} onChange={handleChange} placeholder="終了後アンケートを利用するかチェックしてください" />
          </div>
        </div> */}
        {/* <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="detail_url">プライバシーポリシーURL</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="privacy_policy_url" value={values.privacy_policy_url} onChange={handleChange} placeholder="利用規約にリンクするURLを設定" />
          </div>
        </div> */}
        {Common.getFunctionEnabled(setting, 'CAMPAIGN_LIST') &&
          <div className="row mb-3">
            <div className="col-2 text-center align-self-center">
              <label htmlFor="campaignStart">キャンペーン開始日</label>
            </div>
            <div className="col-3 text-center align-self-center">
              <DatePicker className="custom-select w-100 text-center border-textbox text-primary font-weight-bold" aria_labal="キャンペーン開始日" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id="campaignStart" name="campaignStart"
                selected={values.campaign_start ? new Date(values.campaign_start) : null}
                maxDate={values.campaign_end ? new Date(values.campaign_end) : null}
                onChange={date => handleChangeDate("campaign_start", date ? date : null)}
                placeholderText="- 選択 -"
              />
            </div>
            <div className="col-2 text-center align-self-center">
              <label htmlFor="campaignEnd">キャンペーン終了日</label>
            </div>
            <div className="col-3 text-center align-self-center">
              <DatePicker className="custom-select w-100 text-center border-textbox text-primary font-weight-bold" aria_labal="キャンペーン開始日" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id="campaignEnd" name="campaignEnd"
                selected={values.campaign_end ? new Date(values.campaign_end) : null}
                minDate={values.campaign_start ? new Date(values.campaign_start) : null}
                onChange={date => handleChangeDate("campaign_end", date ? date : null)}
                placeholderText="- 選択 -"
              />
            </div>
          </div>
        }
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="campaignEnd">キャンペーン公開開始日</label>
          </div>
          <div className="col-3 text-center align-self-center">
            <DatePicker className="custom-select w-100 text-center border-textbox text-primary font-weight-bold" aria_labal="キャンペーン開始日" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id="campaignOpen" name="campaignOpen"
              selected={values.campaign_open ? new Date(values.campaign_open) : null}
              maxDate={Common.getFunctionEnabled(setting, 'CAMPAIGN_LIST') ? (values.campaign_start ? new Date(values.campaign_start) : null) : (values.campaign_close ? new Date(values.campaign_close) : null)}
              onChange={date => handleChangeDate("campaign_open", date ? date : null)}
              placeholderText="- 選択 -"
            />
          </div>
          <div className="col-2 text-center align-self-center">
            <label htmlFor="campaignEnd">キャンペーン公開終了日</label>
          </div>
          <div className="col-3 text-center align-self-center">
            <DatePicker className="custom-select w-100 text-center border-textbox text-primary font-weight-bold" aria_labal="キャンペーン開始日" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id="campaignClose" name="campaignClose"
              selected={values.campaign_close ? new Date(values.campaign_close) : null}
              minDate={Common.getFunctionEnabled(setting, 'CAMPAIGN_LIST') ? (values.campaign_end ? new Date(values.campaign_end) : null) : (values.campaign_open ? new Date(values.campaign_open) : null)}
              onChange={date => handleChangeDate("campaign_close", date ? date : null)}
              placeholderText="- 選択 -"
            />
          </div>
        </div>
        {Common.getFunctionEnabled(setting, 'CAMPAIGN_POINT') &&
          <div className="row mb-3">
            <div className="col-2 text-center">
              <span>対象店舗</span>
            </div>
            <div className="col-10 text-left">{campaignShop.length}店舗
              <Select options={shopMaster} isMulti closeMenuOnSelect={false} value={campaignShop} onChange={handleChangeShop} placeholder="対象店舗を選択してください" />
            </div>
          </div>
        }
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>登録完了ページ見出し</span>
          </div>
          <div className="col-5 text-center align-self-center">
            <textarea className="form-control" name="entry_header" value={values.entry_header} onChange={handleChange} placeholder="登録完了ページの見出しを入力してください" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>登録完了ページ本文</span>
          </div>
          <div className="col-5 text-center align-self-center">
            <textarea className="form-control" name="entry_content" value={values.entry_content} onChange={handleChange} placeholder="登録完了ページの本文を入力してください" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>登録完了ページキャンペーン終了案内</span>
          </div>
          <div className="col-5 text-center align-self-center">
            <textarea className="form-control" name="entry_end" value={values.entry_end} onChange={handleChange} placeholder="キャンペーン終了後に登録完了ページを開いたときの案内文を入力してください" />
          </div>
        </div>
        <div className="row mb-2 pb-4">
          <div className="col-3 text-center">
            {renderRegistButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderDeleteButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderResetButton(values)}
          </div>
        </div>
      </div>
    );
  }

  const renderList = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="col-12 p-0">
          <table className="table table-bordered table-striped">
            <thead className={`table-info`}>
              <tr>
                <td className="text-center align-middle">
                  キャンペーンID
                </td>
                <td className="text-center align-middle">
                  キャンペーン名
                </td>
                <td className="text-center align-middle">
                  キャンペーン期間
                </td>
                <td className="text-center align-middle">
                  処理
                </td>
              </tr>
            </thead>
            <tbody>
              {campaignData.map((data, idx) => (
                <tr key={data.campaign_id}>
                  {/* キャンペーンID */}
                  <td className="text-center align-middle">
                    {data.campaign_id}
                  </td>
                  {/* キャンペーン名 */}
                  <td className="text-center align-middle">
                    {data.campaign_name}
                  </td>
                  {/* キャンペーン名 */}
                  <td className="text-center align-middle">
                    {moment(data.campaign_start).format('YYYYMMDD')}～{moment(data.campaign_end).format('YYYYMMDD')}
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle text-nowrap">
                    {renderUpdateButton(data)}
                    {Common.getFunctionEnabled(setting, 'CAMPAIGN_POINT') && renderIconButton(data)}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className="container">

      {campaignData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {campaignData !== null && (renderList())}
      {renderInput()}
    </div>
  )
}

export default Campaign;