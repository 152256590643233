import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';

const User = (props) => {
  const { user, setting } = props;
  const [userData, setUserData] = useState(null);
  const [userNew, setUserNew] = useState({ user_id: '', user_class: 1, user_name: '', user_short: '', password: '', upd_date: null });
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();

  const refreshUser = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');

    const reg_params = {
      "site_id": props.match.params.siteId,
      "operator": "and",
      "where": [{ "site_id": props.match.params.siteId }]
    }

    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/search/`, reg_params,{
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
  
    data.sort((a, b) => a.user_id - b.user_id);
    setUserData(data);

    const new_data = { user_id: '', user_class: 1, user_name: '', user_short: '', password: '', upd_date: null };
    setUserNew(new_data);
  }, [props.match.params.siteId])

  // 登録ボタン：処理
  const handleUserRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    //重複チェック
    if(data.upd_date === null && userData.some(usreData => usreData.user_id === data.user_id)){
      window.alert('既に登録されているユーザIDです。');
      return;
    }

    //入力値チェック
    if (data.user_id === '') {
      window.alert('ユーザIDを入力してください。');
      return;
    }
    if (data.user_id.length > 20) {
      window.alert('ユーザIDは20文字以内で入力してください。');
      return;
    }
    if(data.user_id.trim().match(/\s+/g)){
      window.alert('ユーザIDに空白文字は使用できません。');
      return;
    }
    if (!(String(data.user_id)).match(/^[0-9a-zA-Z]*$/)) {
      window.alert('ユーザIDは半角英数字で入力してください。');
      return;
    }


    if (data.upd_date === null && data.password === '') {
      window.alert('パスワードを入力してください。');
      return;
    }
    if(data.password && (8 > data.password.length || data.password.length > 20) ){
      window.alert('パスワードは8文字以上・20文字以下で入力してください。');
      return;
    }
    if(data.password && data.password.trim().match(/\s+/g)){
      window.alert('パスワードに空白文字は使用できません。');
      return;
    }
    if(data.password && data.password.match(/[^a-zA-Z0-9\-/:;()\\&@".,?!'[\]{}#%^*+=_|~<>$`€£•]+/g)){
      window.alert('パスワードは半角英数記号で入力してください');
      return;
    }


    if (data.user_name === '') {
      window.alert('ユーザ名を入力してください。');
      return;
    }
    if (data.user_name.length > 100) {
      window.alert('ユーザ名は100文字以内で入力してください。');
      return;
    }

    
    if (data.user_short === '') {
      window.alert('ユーザ名略称を入力してください。');
      return;
    }
    if (data.user_short.length > 50) {
      window.alert('ユーザ名略称は50文字以内で入力してください。');
      return;
    }
    const params = {
      site_id: props.match.params.siteId,
      user_id: data.user_id,
      user_class: data.user_class,
      user_name: data.user_name,
      user_short: data.user_short,
      password: data.password ? data.password : null,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch(err){
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshUser();
      }
      setLockData(false);
    }
  }
  
  // 削除ボタン：処理
  const handleUserDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!window.confirm(`ユーザ[${data.user_id}:${data.user_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: props.match.params.siteId,
      user_id: data.user_id,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshUser();
      }
      setLockData(false);
    }
  }

  const handleUserUnlockClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.match.params.siteId,
      user_id: data.user_id
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/unlock/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      addToast('ロックを解除しました。', { appearance: 'success', autoDismiss: true });
      await refreshUser();
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      }
      setLockData(false);
    }

  }

  const handleUserMfaClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!window.confirm(`ユーザ[${data.user_id}:${data.user_name}]の二要素認証(MFA)を削除します。\n次回ログイン時にアプリの登録が必要です。\n削除してもよろしいですか？`)) {
      return;
    }
    const params = {
      site_id: props.match.params.siteId,
      user_id: data.user_id
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/mfa/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      addToast('MFA登録を削除しました。', { appearance: 'success', autoDismiss: true });
      await refreshUser();
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } 
      setLockData(false);
    }

  }

  // value値変更イベント
  const handleChangeCell = (index, key) => (event) => {
    if (index === 'new') {
      const new_data = { ...userNew, [key]: event.target.value };
      console.log(new_data)
      setUserNew(new_data)
    } else {
      const _userData = [...userData]
      _userData[index] = { ...userData[index], [key]: event.target.value }
      console.log(_userData[index])
      setUserData(_userData)
    }
  }

  // 登録ボタン
  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1`}
        onClick={() => handleUserRegistClick(data)}>
        登録
      </button>
    )
  }

  // 削除ボタン
  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.time_id === null}
        className={`btn btn-primary mx-1`}
        onClick={() => handleUserDeleteClick(data)}>
        削除
      </button>
    )
  }

  // ロック解除ボタン
  const renderUnlockButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.time_id === null}
        className={`btn btn-primary mx-1`}
        onClick={() => handleUserUnlockClick(data)}>
        ロック解除
      </button>
    )
  }

  // MFA削除ボタン
  const renderMfaButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.time_id === null}
        className={`btn btn-secondary mx-1`}
        onClick={() => handleUserMfaClick(data)}>
        MFA登録削除
      </button>
    )
  }

  // 初期実行トリガー
  useEffect(() => {
    async function fetchData() {
      await refreshUser();
    }
    return fetchData();
  }, [refreshUser]);

  return (
    <div className="container">
      <div className="row my-2 ">
      </div>

      <div className="row d-none"><div className="col-12">{util.inspect(userData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {userData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {userData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                <tr>
                  <td className="text-center col-1">
                    ユーザID
                  </td>
                  <td className="text-center col-2">
                    パスワード
                  </td>
                  <td className="text-center col-2">
                    ユーザ区分
                  </td>
                  <td className="text-center col-2">
                    ユーザ名
                  </td>
                  <td className="text-center col-2">
                    ユーザ名略称
                  </td>
                  <td className="text-center col-1">
                    更新日時
                  </td>
                  <td className="text-center col-2">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {/* 新規入力行 */}
                <tr key="new">
                  {/* ユーザID */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"user_id_new"} name="user_id" value={userNew.user_id} onChange={handleChangeCell('new', 'user_id')}></input>
                  </td>
                  {/* パスワード */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"password_new"} name="password" value={userNew.password} onChange={handleChangeCell('new', 'password')}></input>
                  </td>
                  {/* ユーザ区分 */}
                  <td className="text-center align-middle">
                    <select className="form-control" name="user_class" id={"user_class_new"} value={userNew.user_class} onChange={handleChangeCell('new', 'user_class')}>
                      <option value={constClass.USER_CLASS.ADMIN}>{constClass.USER_CLASS_NAME.ADMIN}</option>
                      <option value={constClass.USER_CLASS.OPERATOR}>{constClass.USER_CLASS_NAME.OPERATOR}</option>
                    </select>
                  </td>
                  {/* ユーザ名 */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"user_name_new"} name="user_name" value={userNew.user_name} onChange={handleChangeCell('new', 'user_name')}></input>
                  </td>
                  {/* ユーザ名略称 */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"user_short_new"} name="user_short" value={userNew.user_short} onChange={handleChangeCell('new', 'user_short')}></input>
                  </td>
                  {/* 更新日時 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle">
                    {renderRegistButton(userNew)}
                  </td>
                </tr>
                {userData.map((data, idx) => (
                  <tr key={idx}>
                    {/* ユーザID */}
                    <td className="text-center align-middle">
                      {data.user_id}
                    </td>
                    {/* パスワード */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"password" + idx} name="password[]" value={data.password} onChange={handleChangeCell(idx, 'password')}></input>
                    </td>
                    {/* ユーザ区分 */}
                    <td className="text-center align-middle">
                      <select className="form-control" name="user_class[]" id={"user_class" + idx} value={data.user_class} onChange={handleChangeCell(idx, 'user_class')}>
                        <option value={constClass.USER_CLASS.ADMIN}>{constClass.USER_CLASS_NAME.ADMIN}</option>
                        <option value={constClass.USER_CLASS.OPERATOR}>{constClass.USER_CLASS_NAME.OPERATOR}</option>
                      </select>
                    </td>
                    {/* ユーザ名 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"user_name" + idx} name="user_name[]" value={data.user_name} onChange={handleChangeCell(idx, 'user_name')}></input>
                    </td>
                    {/* ユーザ名略称 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"user_short" + idx} name="user_short[]" value={data.user_short} onChange={handleChangeCell(idx, 'user_short')}></input>
                    </td>
                    {/* 更新日時 */}
                    <td className="text-center align-middle">
                      {data.upd_date ? moment(data.upd_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      <div className="mb-1">
                      {renderRegistButton(data)}
                      {renderDeleteButton(data)}
                      </div>
                      <div className="mb-1">
                      {(setting[constClass.SETTING.LOGIN_FAIL_COUNT] && data.fail_count >= Number(setting[constClass.SETTING.LOGIN_FAIL_COUNT])) && renderUnlockButton(data)}
                      </div>
                      {(setting[constClass.SETTING.MFA_USE] === constClass.FLAG.ON && data.m_user_secret.length >= 1) && renderMfaButton(data)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default User;