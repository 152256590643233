import React, { useState, useCallback } from 'react';
import axios from 'axios';
import VConsole from 'vconsole';
import QRCodeScanner from './QRCodeScanner';
import Calculator from './Calculator';
import PointDetail from './PointDetail';
import constClass from '../../Constants/Constants';

const initialAppState = { 
  site_id: null,
  customer_id: null,

  qrcode_err: null,
  membership_id: null,  //会員ID
  customer_name: '',    //お客様名
  available_point: 0,   //利用可能ポイント
  customer_rank: null,    //会員ランク

  inputValue: "0",      //対象金額
  pointUseFlag: null,       //ポイント利用(つかう:true,ためる:false)
  usePoint: 0,        //利用ポイント
  paymentValue: 0,    //支払金額
  addPoint: 0,        //加算ポイント
  pointBalance: 0,    //ポイント残高
};

const QRApp = (props) => {
  const [message, setMessage] = useState('');
  const [scanning, setScanning] = useState(true);
  const [mode, setMode] = useState(constClass.POINT_SCREEN.MAIN);
  const [appState, setAppState] = useState(initialAppState);

  if (process.env.REACT_APP_ENV !== 'pro') {
    new VConsole();
  }

  const setQrCodeData = useCallback(async (qrData) => {
    if (qrData) {
      // var winRef;
      setScanning(false);

      const jwt = localStorage.getItem('jwt');
      const siteId = props.match.params.siteId;

      try {
        //会員情報を取得
        const customer_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/card/${siteId}`, { qr_data: qrData }, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        console.log(customer_data);
        const customerId = customer_data.customer_id;

        //有効ポイントの合計を取得
        const point_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/points/backyard/available_sum/`, { site_id: siteId, customer_id: customerId }, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        const point_info = point_data.find(c => c.customer_id === customerId);
        var available_point = 0;
        if (point_info !== undefined) {
          available_point = point_info.sum_point - point_info.sum_used_point;
        }
      
        //ランク判定期間内のポイントを取得
        const rank_point = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/points/backyard/period_point/`, { site_id: siteId, customer_id: customerId }, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        const rank_point_info = rank_point.find(c => c.customer_id === customerId);
        var user_rank_point = 0;
        if (rank_point_info !== undefined) {
          user_rank_point = Number(rank_point_info.sum_point);
        }
      
        //ランクマスタ情報を取得
        const rank_info = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customerrank/ranklist/`, { site_id: siteId }, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        //現在のユーザのランクを設定
        const rankInfo = rank_info.find(data => data.point_lower <= user_rank_point && user_rank_point <= data.point_upper);
        //該当するランクがない場合、最後(point_upperが最高)のランクに設定
        const user_rank = rankInfo ? rankInfo.rank_id : rank_info[rank_info.length - 1].rank_id;
      
        setAppState({
          ...appState,
          site_id: '' + customer_data.site_id,
          customer_id: customerId,
          qrcode_err: null,
          membership_id: customer_data.card_id,
          customer_name: customer_data.customer_name,
          available_point,
          customer_rank: user_rank >= 1 ? rank_info[user_rank - 1] : null,
        });
        // const intervalId = window.setInterval(() => {
        //   window.clearInterval(intervalId);
        //   window.close();
        // }, 5000);
      } catch (err) {
        setAppState({
          ...appState,
          site_id: null,
          customer_id: null,
          qrcode_err: err.response.data ? err.response.data.msg : err.response,
          membership_id: null,
          customer_name: '',
          available_point: 0,
          customer_rank: null,
        })
      }

      setMode(constClass.POINT_SCREEN.MAIN);
      setScanning(true);

    } else {
      setMessage('読み取りに失敗しました。再度読み取りをお願いします。');
    }
  }, [appState, props.match.params.siteId]);


  const setPointRegist = useCallback(async () => {
    const siteId = props.match.params.siteId;
    const params = {...appState};
    console.log(params);
    try {
      const jwt = localStorage.getItem('jwt');
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/points/${siteId}`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      if (res && res.data.error) {
        window.alert(res.data.message);
      }
      else {
        window.alert('完了しました。');
        setAppState(initialAppState);
        setMode(constClass.POINT_SCREEN.MAIN);
      }

    } catch (err) {
      if (err.response.data !== null) {
        window.alert(err.response.data.message);
      } else {
        window.alert(err.response);
      }
    } finally {

    }

  }, [appState, props.match.params.siteId]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col text-center">
          <span>ポイント端末</span>
        </div>
      </div>
      { mode === constClass.POINT_SCREEN.MAIN && <Calculator setMode={setMode} appState={appState} setAppState={setAppState} /> }
      { mode === constClass.POINT_SCREEN.CAMERA && <QRCodeScanner {...props} setMode={setMode} setQrCodeData={setQrCodeData} message={message} scanning={scanning} /> }
      { mode === constClass.POINT_SCREEN.DETAIL && <PointDetail {...props} setMode={setMode} appState={appState} setPointRegist={setPointRegist} /> }
    </div>
  );
}

export default QRApp;