import { useBarcode } from '@createnextapp/react-barcode'

//コードに沿ったバーコードのイメージを返却
const Barcode = ({code}) => {

  const { inputRef } = useBarcode({
    value: code,
    options: {
      background: '#ffffff',
    }
  });

  return (    
    <div className="pass-img">
      <img className="img-fluid d-block mx-auto" ref={inputRef} alt="Barcord"/>
    </div>
  );
}

export default Barcode;