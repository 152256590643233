import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { CSVLink } from "react-csv";
import { useParams, generatePath } from 'react-router';

const PaymentHistory = (props) => {
  const { siteId } = useParams();
  const [paymentHistory, setPaymentHistory] = useState(null);
  const [condition, setCondition] = useState({ monthFrom: moment().add(-3, 'month').format("YYYYMM"), monthTo: moment().format("YYYYMM") });
  const [searchedCondition, setSearchedCondition] = useState({ monthFrom: moment().add(-3, 'month').format("YYYYMM"), monthTo: moment().format("YYYYMM") });
  const [searchFlag, setSearchFlag] = useState(true);
  const [errMsg, setErrMsg] = useState({ monthFrom: '', monthTo: '' });
  const componentRef = useRef();
  const [csvData, setCsvData] = useState(null);
  const [csvAmountData, setCsvAmountData] = useState(null);
  const [csvDetailData, setCsvDetailData] = useState(null);
  const [lockData, setLockData] = useState(false);

  const conditionList = useMemo(() => {
    const ary = [];
    for (var m = moment(searchedCondition.monthFrom, 'YYYYMM'); m <= moment(searchedCondition.monthTo, 'YYYYMM'); m = m.add(1, 'month')) {
      ary.push(m.format('YYYYMM'));
    }
    return ary;
  }, [searchedCondition]);

  const createCsvData = useCallback((data) => {
    const ary = conditionList;
    const customers = [...new Set(data.map(p => p.customer_id))];
    const list = [];
    const listAmount = [];
    const head = [];
    head.push({ label: "No", key: "customer_id" });
    ary.forEach(m => {
      head.push({ label: m, key: m });
    });
    customers.forEach(customer_id => {
      const record = {
        customer_id,
      };
      const recordAmount = {...record};
      ary.forEach(m => {
        const target = data.filter(p => p.customer_id === customer_id && p.month === m);
        record[m] = target.map(p => moment(p.transaction_date).format('YYYY/MM/DD HH:mm:ss')).join('\n');//  + ' (\\' + Number(p.amount).toLocaleString() + ')'
        recordAmount[m] = target.reduce((prev, p) => prev + Number(p.amount), 0);
      });
      list.push(record);
      listAmount.push(recordAmount);
    });
    const summary = {
      customer_id: '件数',
    };
    const summaryAmount = {
      customer_id: '合計',
    };
    ary.forEach(m => {
      summary[m] = data.filter(p => p.month === m).length;
      summaryAmount[m] = data.filter(p => p.month === m).reduce((prev, p) => prev + Number(p.amount), 0);
    });
    list.push(summary);
    listAmount.push(summaryAmount);

    setCsvData({ header: head, list: list });
    setCsvAmountData({ header: head, list: listAmount });
  }, [conditionList]);

  const refreshCustomer = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    // useCallback対応のため検索ボタンを押したときのみ実行
    if (searchFlag) {
      if (errMsg.monthFrom === '' && errMsg.monthTo === '') {
        const reg_params = {
          site_id: siteId,
          operator: 'and',
          where: [
            { site_id: siteId },
            { operator: 'gte', attr: 'month', val: condition.monthFrom },
            { operator: 'lte', attr: 'month', val: condition.monthTo },
            { job_cd: 'SALES' }
          ]
        }
        // お客様データ
        setLockData(true);
        const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/payment_history_search/`, reg_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        setPaymentHistory(data);
        createCsvData(data);
        createCsvDetailData(data);
        setLockData(false);
        setSearchedCondition({ ...condition });
      }
    }
    setSearchFlag(false);
  }, [searchFlag, errMsg, siteId, condition, createCsvData]);

  const handleChangeCondition = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setCondition({ ...condition, [name]: value });
    setErrMsg({ ...errMsg, [name]: validator(name, value) });
  }

  const validator = (name, value) => {
    switch (name) {
      case 'monthFrom':
      case 'monthTo':
        return yearValidation(value);
      default:
        return '';
    }
  }

  const yearValidation = (value) => {
    if (value === '') return '';
    const regex = /^[0-9]{4}(0[1-9]|1[0-2])$/;
    if (!regex.test(value)) return '※正しい形式で年を入力してください';
    return '';
  }

  const createCsvDetailData = (data) => {
    const list = [];
    const head = [];
    head.push({ label: "No", key: "customer_id" });
    head.push({ label: "履歴番号", key: "history_no" });
    head.push({ label: "年月", key: "month" });
    head.push({ label: "取引日時", key: "transaction_date" });
    head.push({ label: "取引ID", key: "transaction_id" });
    head.push({ label: "金額", key: "amount" });
    data.forEach(d => {
      const record = {
        ...d,
        transaction_date: moment(d.transaction_date).format('YYYY/MM/DD HH:mm:ss')
      };
      list.push(record);
    });

    setCsvDetailData({ header: head, list: list });
  }

  useEffect(() => {
    refreshCustomer();
  }, [refreshCustomer]);

  const renderHeader = () => {
    const ary = conditionList;
    return (
      <React.Fragment>
        {ary.map(m =>
          <th className="text-center align-middle text-nowrap" key={m}>{m}</th>
        )}
      </React.Fragment>
    )
  }

  const renderBody = () => {
    const ary = conditionList;
    const customers = [...new Set(paymentHistory.map(p => p.customer_id))];
    return (
      <tbody>
        {customers.map(customer_id =>
          <tr key={customer_id}>
            <td className="text-center align-middle text-nowrap">
              <Link to={`${generatePath(`${props.match.path}`, { siteId })}customer/${customer_id}`}>{customer_id}</Link>
            </td>
            {ary.map(m =>
              <td className="text-center align-middle text-nowrap" key={customer_id + '_' + m}>
                {paymentHistory.filter(p => p.customer_id === customer_id && p.month === m).map(p =>
                  <p key={customer_id + '_' + m + '_' + p.history_no} className="mb-0">{moment(p.transaction_date).format('YYYY/MM/DD HH:mm:ss')} (&yen;{Number(p.amount).toLocaleString()})</p>
                )}
              </td>
            )}
          </tr>
        )}
        <tr>
          <td className="text-center align-middle text-nowrap">
            件数(合計)
          </td>
          {ary.map(m =>
            <td className="text-center align-middle text-nowrap" key={'summary_' + m}>
              {paymentHistory.filter(p => p.month === m).length}件 (&yen;{paymentHistory.filter(p => p.month === m).reduce((prev, p) => prev + Number(p.amount), 0).toLocaleString()})
            </td>
          )}
        </tr>
      </tbody>
    )
  }

  return (
    <div className="container">
      <div className="row mx-0 my-2 px-1-env">
        <div className="col">
          <div className="row my-1">
            <div className="col-4">
              <div className="row">
                <div className="col-auto text-center align-self-center">
                  <span>年月</span>
                </div>
                <div className="col text-center">
                  <input className={`form-control ${errMsg.monthFrom !== '' ? 'is-invalid' : ''}`} type="text" id="monthFrom" name="monthFrom" value={condition.monthFrom} onChange={handleChangeCondition}></input>
                </div>
                <div className="col-auto text-center align-self-center">
                  <span>～</span>
                </div>
                <div className="col text-center">
                  <input className={`form-control ${errMsg.monthTo !== '' ? 'is-invalid' : ''}`} type="text" id="monthTo" name="monthTo" value={condition.monthTo} onChange={handleChangeCondition}></input>
                </div>
              </div>
            </div>
            <div className="col-1 text-center">
              <button type="button"
                className={`btn btn-primary mx-1 w-100`}
                disabled={errMsg.monthFrom !== '' || errMsg.monthTo !== '' || lockData}
                onClick={() => setSearchFlag(true)}>
                検索</button>
            </div>
          </div>
        </div>
      </div>
      {paymentHistory === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {paymentHistory !== null && (
        <div ref={componentRef} className="print-list">
          <div className="row mb-0 p-0 ">
            <div className="col-10 pl-0 text-left align-self-end">
              <div className="row mr-0">
                <div className="col-2 pr-2 align-self-center">
                  {paymentHistory[0] &&
                    <ReactToPrint
                      trigger={() => (
                        <button type="button" className="btn btn-primary print-none px-0 mb-1 w-100">
                          一覧表印刷
                        </button>
                      )}
                      content={() => componentRef.current}
                      pageStyle={""}
                    />}
                </div>
                <div className="col-2 px-2 align-self-center">
                  {csvData &&
                    <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={csvData.list} headers={csvData.header} filename={"決済日付一覧.csv"}>
                      CSV出力(日付)
                    </CSVLink>
                  }
                </div>
                <div className="col-2 px-2 align-self-center">
                  {csvAmountData &&
                    <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={csvAmountData.list} headers={csvAmountData.header} filename={"決済金額一覧.csv"}>
                      CSV出力(金額)
                    </CSVLink>
                  }
                </div>
                <div className="col-2 px-2 align-self-center">
                  {csvDetailData &&
                    <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={csvDetailData.list} headers={csvDetailData.header} filename={"決済明細.csv"}>
                      CSV出力(明細)
                    </CSVLink>
                  }
                </div>
              </div>
            </div>
            <div className="col-2 px-2 align-self-center text-right border h-100">
              <div className="row">
                <div className="col-auto py-1 px-2 bg-lightwhite h-100">一覧表件数</div>
                <div className="col py-1">{paymentHistory.length.toLocaleString()}</div>
              </div>
            </div>
          </div>
          <div className="row mb-3 p-0">
            <div className="col-12 p-0">
              <table className="table table-bordered table-striped" height="1">
                <thead className={`table-info`}>
                  <tr>
                    <th className="text-center align-middle text-nowrap">
                      No
                    </th>
                    {renderHeader()}
                  </tr>
                </thead>
                {renderBody()}
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PaymentHistory;