import React from 'react';
import constClass from '../../Constants/Constants';

const style = {
  result: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    fontSize: "2.5rem",
  },
};

const Calculator = ({setMode, appState, setAppState}) => {

  const handlePointUseClick = (type) => {
    var state = {
      ...appState,
      pointUseFlag: type,
    };
    setAppState(state);
  }

  const handleNumClick = (n) => {
    var state = {
      ...appState,
      inputValue: appState.inputValue === "0" ? n : appState.inputValue + n,
    };
    setAppState(state);
  }
  const handleOperatorClick = (t) => {
    var state = {
      ...appState,
    };
    switch(t) {
      case "📷":
        setMode(constClass.POINT_SCREEN.CAMERA);
        break;
      case "⏎":
        state.usePoint = state.pointUseFlag ? Math.min(state.available_point, state.inputValue) : 0;
        state.paymentValue = state.inputValue - state.usePoint;
        state.addPoint = Math.floor(state.paymentValue * (state.customer_rank ? state.customer_rank.point_rate : 0)); //ポイントは端数切り捨て
        state.pointBalance = state.available_point - state.usePoint + state.addPoint;
        setMode(constClass.POINT_SCREEN.DETAIL);
        break;
      case "C":
        state.inputValue = "0";
        break;
      default:
    }
    setAppState(state);
  }
  
  
  const Result = ({ result }) => (
    <div className="h-100">
      <span className="h-100" style={style.result}>{Number(result).toLocaleString('ja-JP', {style:'currency', currency: 'JPY'})}</span>
    </div>
  );
  const NumBtn = ({n, className}) => (
    <button className={className} onClick={() => { handleNumClick(n) }}>{n}</button>
  );
  const OperatorBtn = ({t, className, disabled}) => (
    <button disabled={disabled ?? false} className={className} onClick={() => { handleOperatorClick(t) }}>{t}</button>
  );
 
  
  return (
    <div>
      <div className="row">
        <div className="col my-3 text-left bg-white">
          <div className="card-header p-2 border">
            <span><b>会員ID:</b></span>
            <span className="float-right pl-2">{appState.qrcode_err ? appState.qrcode_err : appState.membership_id}</span>
          </div>
          <div className="card-header p-2 text-left border">
            <span><b>利用可能ポイント:</b></span>
            <span className="float-right pr-2"><b>{appState.available_point.toLocaleString()}</b> ポイント</span>
          </div>
          <div className="card-header p-2 text-left border">
            <span><b>会員ランク:</b></span>
            <span className="float-right pr-2"><b>{appState.customer_rank ? appState.customer_rank.rank_name : null}</b></span>
          </div>
          <div className="card-header p-2 text-left border">
            <span><b>ポイント還元率:</b></span>
            <span className="float-right pr-2"><b>{appState.customer_rank ? appState.customer_rank.point_rate * 100 : null}</b> ％</span>
          </div>
        </div>
      </div>
      <table className="table h-100">
        <tbody>
          <tr>
            <td className="p-0" height="60" colSpan="4"><Result result={ appState.inputValue } /></td>
          </tr>
          <tr>
            <td className="text-center" height="80" colSpan="2">
              <button
                className={`btn ${appState.pointUseFlag === false ? "btn-active" : "btn-enable"} w-75 h-100 p-1`}
                onClick={() => { handlePointUseClick(false) }}>
                ためる
              </button>
            </td>
            <td className="text-center" height="80" colSpan="2">
              <button
                className={`btn ${appState.pointUseFlag === true ? "btn-active" : "btn-enable"} w-75 h-100 p-1`}
                onClick={() => { handlePointUseClick(true) }}>
                つかう
              </button>
            </td>
          </tr>
          <tr>
            <td className="p-0" height="60"><NumBtn className={"w-100 h-100"} n={"7"} /></td>
            <td className="p-0" height="60"><NumBtn className={"w-100 h-100"} n={"8"} /></td>
            <td className="p-0" height="60"><NumBtn className={"w-100 h-100"} n={"9"} /></td>
            <td className="p-0" height="60" width="25%" rowSpan="2"><OperatorBtn className={"w-100 h-100"} t={"📷"} /></td>
          </tr>
          <tr>
            <td className="p-0" height="60"><NumBtn className={"w-100 h-100"} n={"4"} /></td>
            <td className="p-0" height="60"><NumBtn className={"w-100 h-100"} n={"5"} /></td>
            <td className="p-0" height="60"><NumBtn className={"w-100 h-100"} n={"6"} /></td>
          </tr>
          <tr>
            <td className="p-0" height="60"><NumBtn className={"w-100 h-100"} n={"1"} /></td>
            <td className="p-0" height="60"><NumBtn className={"w-100 h-100"} n={"2"} /></td>
            <td className="p-0" height="60"><NumBtn className={"w-100 h-100"} n={"3"} /></td>
            <td className="p-0" height="60" width="25%" rowSpan="2"><OperatorBtn className={"w-100 h-100"} t={"⏎"} disabled={appState.membership_id === null || appState.pointUseFlag === null || appState.inputValue === "0"} /></td>
          </tr>
          <tr>
            <td className="p-0" height="60" colSpan="2"><NumBtn className={"w-100 h-100"} n={"0"} /></td>
            <td className="p-0" height="60"><OperatorBtn className={"w-100 h-100"} t={"C"} /></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Calculator;