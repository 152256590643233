import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

const Setting = (props) => {
  const { siteId } = useParams();
  const { user } = props;
  const history = useHistory();
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();
  const [settingData, setSettingData] = useState(null);

  const refreshSetting = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const result = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/setting/search/`, {site_id: siteId}, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    var data = result.filter(res => res.site_id === Number(siteId) && res.setting_name); // setting_nameが含まれているもののみ設定可能
    // data.sort((a, b) => a.setting_name < b.setting_name ? -1 : 1); // サーバサイドソート
    setSettingData(data);
  },[siteId]);

  // 複数レコード　val値change (更新のみ)
  const handleChangeCell = (index, key) => (event) => {
    const _settingData = [...settingData]
    _settingData[index] = { ..._settingData[index], [key]: event.target.value, isUpdate: true }
    setSettingData(_settingData);
  }

  const handleUpdateClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    // 確認メッセージ
    if (!window.confirm(`設定マスタを更新します。よろしいですか？`)) {
      return;
    }

    let res;
    try {
      setLockData(true);
      if (data.isUpdate === true) {
        let params = {
          setting_type: data.setting_type,
          setting_val: data.setting_val,
        };

        res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/setting/update/${props.match.params.siteId}/${data.setting_type}`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      };

    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
        await refreshSetting();
      }
      setLockData(false);
    }

  }

  const updateButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || !data.isUpdate}
        className={`btn btn-primary mx-1 text-nowrap`}
        onClick={() => handleUpdateClick(data)}>
        更新
      </button>
    )
  }

  useEffect(() => {
    async function fetchData() {
      await refreshSetting();
    }
    return fetchData();
  }, [history, props.match.path, refreshSetting, user.userClass]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {settingData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}

      {settingData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR.BUS}`}>
                <tr>
                  <td className="text-center">
                    No
                  </td>
                  <td className="text-center">
                    設定名称
                  </td>
                  <td className="text-center">
                    設定備考
                  </td>
                  <td className="text-center w-50">
                    設定値
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {settingData.map((data, idx) => ( 
                  <tr key={data.setting_type}>
                    {/* 設定名称 */}
                    <td className="text-center align-middle">
                      {data.setting_order}{data.setting_order_sub && '-' + data.setting_order_sub}
                    </td>
                    {/* 設定名称 */}
                    <td className="text-left align-middle">
                      {data.setting_name}
                    </td>
                    {/* 設定備考 */}
                    <td className="text-left align-middle text-prewrap">
                      {data.setting_note}
                    </td>
                    {/* 設定値 */}
                    <td className="text-right align-middle">
                      <textarea rows={1} className="form-control" id={"setting_val" + data.setting_type} name="setting_val[]" value={data.setting_val || ""} onChange={handleChangeCell(idx, 'setting_val')}></textarea>
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {updateButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default Setting