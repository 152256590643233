import React, { useState, useEffect, useCallback } from 'react';
import Barcode from './Barcord';
//import CheckDigit from './CheckDigit';
import Logo from '../Images/nnrg_logo.png';
import axios from 'axios';
import moment from 'moment';
import QRCode from "react-qr-code";

let membership_id = null;

const MembershipPass = ({ siteId, customerId, lineId, liffAccessToken, setting }) => {
  const [qrData, setQrData] = useState(null);
  const [validTime, setValidTime] = useState(moment());
  const [restTime, setRestTime] = useState(0);
  const [userInfo, setUserInfo] = useState({
    membership_id: null, //会員ID
    customer_name: '', //お客様名
    available_point: 0, //利用可能ポイント
    expire_point: 0, //有効期限が直近の残りポイント
    expire_point_date: null, //有効期限が直近の期日
    user_rank_point: 0, //会員ランクを決定する為のポイント
    user_rank: 1, //現在の会員ランク
  });

  const [rankCss, setRankCss] = useState([{}]);

  const getQrData = useCallback(async () => {
    const qr_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/line/card/qrdata/${siteId}/`, { site_id: siteId, line_id: liffAccessToken })).data;
    setQrData(qr_data);
    const date = moment();
    setValidTime(date + setting.MEMBER_CARD_QRCODE_VALID_TIME * 60 * 1000);
  }, [siteId, liffAccessToken, setting]);

  //バックエンドサーバから会員に関する情報を取得
  const refreshData = useCallback(async () => {
    try {
      //会員名を取得
      const customer_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/line/search/`, { site_id: siteId, line_id: liffAccessToken })).data;
      const customer_info = customer_data.find(c => c.line_id === lineId);
      membership_id = customer_info.card_id;
      
      //有効ポイントの合計を取得
      const point_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/points/line/available_sum/`, { site_id: siteId, customer_id: customerId, line_id: liffAccessToken })).data;
      const point_info = point_data.find(c => c.customer_id === customerId);
      var available_point = 0;
      if (point_info !== undefined) {
        available_point = point_info.sum_point - point_info.sum_used_point;
      }

      //ランク判定期間内のポイントを取得
      const rank_point = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/points/line/period_point/`, { site_id: siteId, customer_id: customerId, line_id: liffAccessToken })).data;
      const rank_point_info = rank_point.find(c => c.customer_id === customerId);
      var user_rank_point = 0;
      if (rank_point_info !== undefined) {
        user_rank_point = Number(rank_point_info.sum_point);
      }

      //ランクマスタ情報を取得
      const rank_info = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customerrank/line/ranklist/`, { site_id: siteId, line_id: liffAccessToken })).data;
      //現在のユーザのランクを設定
      const rankInfo = rank_info.find(data => data.point_lower <= user_rank_point && user_rank_point <= data.point_upper);
      //該当するランクがない場合、最後(point_upperが最高)のランクに設定
      const user_rank = rankInfo ? rankInfo.rank_id : rank_info[rank_info.length - 1].rank_id;

      //有効期限が記載されているかつ、有効期限内のポイントリストを取得
      const expire_point_list = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/points/line/expire_point_list/`, { site_id: siteId, customer_id: customerId, line_id: liffAccessToken })).data;
      var expire_point = 0;
      var expire_point_date = null;
      //取得出来た場合、下記処理を実施
      if(expire_point_list.length){
        //有効期限が一番近いレコードの日時情報をセット
        const expire_point_info = expire_point_list.find(c => c.customer_id === customerId);
        expire_point_date = expire_point_info.expire_date;
        //日時が同じレコードの利用可能ポイントを合計
        for (var i = 0; i < expire_point_list.length; i++) {
          if(expire_point_date === expire_point_list[i].expire_date){
            expire_point += expire_point_list[i].point - expire_point_list[i].used_point;
          }    
        }
      };

      //取得したユーザ情報をステートフックに設定
      setRankCss(rank_info);
      setUserInfo({
        membership_id: membership_id,
        customer_name: customer_info.customer_name,
        available_point: available_point,
        expire_point: expire_point,
        expire_point_date: expire_point_date,
        user_rank_point: user_rank_point,
        user_rank: user_rank,
      });
    } catch (err) {
      console.log(err);
    }
  }, [siteId, customerId, lineId, liffAccessToken]);

  useEffect(() => {
    refreshData();
    getQrData();
  }, [refreshData, getQrData]);

  useEffect(() => {
    var intervalId;
    intervalId = setInterval(() => {
      const date = moment();
      const sec = Math.floor((validTime - date) / 1000);
      setRestTime(sec);
      if (sec <= 0) {
        getQrData();
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [validTime, getQrData]);

  return (
    <div id="membershippass" className="px-0-env">
      <div className="row mx-0">
        <div className="col mx-3 my-1 p-0 text-left bg-white">
          <div className={`mx-2 p-1 border border-dark rounded ` + (rankCss[userInfo.user_rank - 1].pass_css)}>
            <div className="pass-img">
              <img src={Logo} alt="logo" />
            </div>
            {userInfo.membership_id !== null && setting.MEMBER_CARD_CODE_TYPE === '1' && (<Barcode code={userInfo.membership_id} />)}
            {userInfo.membership_id !== null && setting.MEMBER_CARD_CODE_TYPE === '2' && qrData !==null && (<div className="qr-box"><QRCode className="qr-img" size={100} value={qrData} /></div>)}
          </div>
          {userInfo.membership_id !== null && setting.MEMBER_CARD_CODE_TYPE === '2' && qrData !==null && (<div className="mx-2 qrcode-resttime">二次元コードの有効時間：{restTime}秒</div>)}
          <div className="col text-left bg-white">
            <div className="card-header p-2 border">
              <span><b>会員ID:</b></span>
              <span className='float-right pr-2'>{userInfo.membership_id}</span>
            </div>
            {(setting.INPUT_CUSTOMER_NAME === "1" || setting.INPUT_CUSTOMER_NAME === "2") && <div className="card-header p-2 border">
              <span><b>氏名: </b></span>
              <span className='float-right pr-2'>{userInfo.customer_name ? userInfo.customer_name : '(未登録)'}</span>
            </div>
            }
            <div className="card-header p-2 border">
              <span><b>会員ランク: </b></span>
              {/*会員ランクで変更=>text-gold text-silver text-bronze*/}
              <span className={`float-right pr-2 fuchidori ` + (rankCss[userInfo.user_rank - 1].rank_css)}>
                <b>{(rankCss[userInfo.user_rank - 1].rank_name)}</b>
              </span>
            </div>
          </div>
          <div className="col my-3 bg-white">
            <div className="card-header p-2 text-left border">
              <span><b>利用可能ポイント:</b></span>
              <span className="float-right pr-2"><b>{userInfo.available_point.toLocaleString()}</b> ポイント</span>
            </div>

            <div className="card-header p-2 border">
              <span><b>ポイント有効期限: </b></span>
              <span className="float-right pr-2">
                {userInfo.expire_point_date === null && "-"}
                {userInfo.expire_point_date !== null && moment(userInfo.expire_point_date).format('YYYY/MM/DD HH:mm') + `　(` + userInfo.expire_point.toLocaleString() + `ポイント)`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MembershipPass;