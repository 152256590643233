import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import util from 'util';
import constClass from '../../Constants/Constants';
import Common from '../Common/common';

const NavBar = (props) => {
  const { user, userActions, siteName, setting } = props;
  const history = useHistory();

  const handleTitleClick = () => {
    userActions.refresh();
    history.replace(`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}`);
  }

  const handleLogoutClick = () => {
    localStorage.removeItem('jwt');
    userActions.logout();
    history.replace(`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}login`);
  }

  // const handlePlanCategoryClick = () => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}plancategory`);
  // }

  // const handleProductCategoryClick = () => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}productcategory`);
  // }

  // const handleOrderInputClick = () => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}order/input`);
  // }

  // const handlePlanClick = () => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}plan`);
  // }

  // const handleProductClick = () => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}product`);
  // }

  return (
    <nav className="navbar navbar-light navbar-expand-lg  bg-light fixed-top">
      <Link className="navbar-brand" onClick={handleTitleClick} to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}`}>
        {`${siteName}`}<div className='d-none'>{util.inspect(history)}</div>
      </Link>
      {
        user.isLoggedIn &&
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      }
      {
        user.isLoggedIn &&
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}`}>お客様一覧</Link>
            </li>
            {Common.getFunctionEnabled(setting, 'CAMPAIGN') &&
              <li className="nav-item">
                <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}campaignentry`}>キャンペーンエントリー一覧</Link>
              </li>
            }
            {(user.userClass === constClass.USER_CLASS.ADMIN && Common.getFunctionEnabled(setting, 'CAMPAIGN')) &&
              <li className="nav-item dropdown">
                <button className="nav-link nav-btn dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  キャンペーン管理
                </button>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}campaign`}>キャンペーン管理</Link>
                  {setting['CAMPAIGN_MESSAGE_SEND'] === constClass.FLAG.ON && <Link className="dropdown-item" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}campaignpush`}>キャンペーン通知管理</Link>}
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}campaignpage`}>キャンペーン説明ページ管理</Link>
                  {Common.getFunctionEnabled(setting, 'CAMPAIGN_POINT') && <Link className="dropdown-item" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}campaignpoints`}>キャンペーンポイント管理</Link>}
                </div>
              </li>
            }
            {(user.userClass === constClass.USER_CLASS.ADMIN && Common.getFunctionEnabled(setting, 'COUPON')) &&
              <li className="nav-item">
                <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}coupon`}>クーポン管理</Link>
              </li>
            }
            {(user.userClass === constClass.USER_CLASS.ADMIN && Common.getFunctionEnabled(setting, 'TICKET')) &&
              <li className="nav-item">
                <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}ticket`}>チケット管理</Link>
              </li>
            }
            {(setting && setting['PAYMENT_REQUIRED'] === constClass.FLAG.ON) &&
              <li className="nav-item">
                <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}paymenthistory`}>決済履歴</Link>
              </li>
            }
            {user.userClass === constClass.USER_CLASS.ADMIN &&
              <li className="nav-item dropdown">
                <button className="nav-link nav-btn dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  システム管理
                </button>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}setting`}>設定マスタ</Link>
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}user`}>ユーザマスタ</Link>
                  {Common.getFunctionEnabled(setting, 'MEMBERCARD') && <Link className="dropdown-item" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}customerrank`}>会員ランクマスタ</Link>}
                </div>
              </li>
            }
            {(setting && setting['FUNC_MEMBERCARD'] === '1' && setting['MEMBER_CARD_CODE_TYPE'] === '2') &&
              <li className="nav-item">
                <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}pointterminal`}>ポイント端末</Link>
              </li>
            }
            {Common.getFunctionEnabled(setting, 'MANUAL_DOWNLOAD') &&
              <li className="nav-item">
                <a className="nav-link" href="../../pdf/LINEデジタル会員証操作マニュアル.pdf" target="_blank" rel="noreferrer">マニュアル</a>
              </li>
            }
            {/* {user.userClass === constClass.CLASS.CHECK && */}
            {/* <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  設定
              </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a className="dropdown-item" href="#" onClick={handleDispCloseClick}>発券停止</a>
                  <a className="dropdown-item" href="#" onClick={handleLineCloseClick}>LINE発券停止</a>
                  <a className="dropdown-item" href="#" onClick={handleDispOpenClick}>発券再開</a>
                  <a className="dropdown-item" href="#" onClick={handleLineOpenClick}>LINE発券再開</a>
                  <a className="dropdown-item" href="#" onClick={handleWaitOpenClick}>順番待ち開始</a>
                  <a className="dropdown-item" href="#" onClick={handleWaitCloseClick}>順番待ち停止</a>
                </div>
              </li> */}
            {/* } */}
          </ul>
        </div>
      }
      {
        user.isLoggedIn &&
        <div>
          <label className="mr-2 text-primary">{user.userName}</label>
          <button className="btn btn-dark" onClick={handleLogoutClick}>ログアウト</button>
        </div>
      }
    </nav>
  );
}

export default NavBar;