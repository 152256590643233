import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Loading from '../Loading/Loading';
import constClass from '../../Constants/Constants';
import Common from '../Common/common';

const Payment = ({ siteId, lineId, id, amount, fee, backUrl, returnUrl, backFunc, returnFunc, setting, change, customer_id, monthly_amount, payment_message, payment_status, cancelFunc, successFunc }) => {
  const [values, setValues] = useState({
    cardNo: '',
    month: '',
    year: '',
    sec: '',
    method: '',
    payTimes: '',
    cardId: '',
    paymentType: '',
  });
  const [message, setMessage] = useState({
    cardNo: '',
    month: '',
    year: '',
    sec: '',
    method: '',
    payTimes: '',
    cardId: '',
    paymentType: '',
  });
  const location = useLocation();
  const [confirm, setConfirm] = useState(false);
  const [result, setResult] = useState(null);
  const [lockData, setLockData] = useState(false);
  const [member, setMember] = useState({});
  const [methods, setMethods] = useState([]);
  const [payTimesList, setPayTimesList] = useState(['02', '03', '05', '06', '10', '12', '15', '18', '20', '24']);
  const history = useHistory();
  const year = useMemo(() => new Date().getFullYear(), []);
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const [years, setYears] = useState([year, year + 1, year + 2, year + 3, year + 4, year + 5, year + 6, year + 7, year + 8, year + 9, year + 10]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const methodInstallment = '2';
  const [paymentMessage, setPaymentMessage] = useState(payment_message);
  const [siteName, setSiteName] = useState();

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? (target.checked ? '1' : '0') : target.value;
    const name = target.name;
    setValues({ ...values, [name]: value });
    const msg = message;
    setMessage({ ...msg, [name]: validator(name, value) });
  }

  const validator = (name, value) => {
    switch (name) {
      case 'cardNo':
        return cardNoValidation(value);
      case 'sec':
        return securityCodeValidation(value);
      default:
        return '';
    }
  }

  const cardNoValidation = (value) => {
    // Visa:4 13桁or16桁 /4[0-9]{12}(?:[0-9]{3})?/
    // MasterCard:510000 - 559999, 222100 - 272099 16桁 /(?:5[1-5]|2[2-7])[0-9]{14}/
    // Discover Card:60110, 60112-60114, 601174 - 601179, 601186 - 601199, 644 - 649, 65 16桁 /6(?:011|4[4-9][0-9]|5[0-9]{2})[0-9]{12}/
    // Diners Club:300 - 303574, 3095, 36, 38 - 39 14桁 /3(?:0[0-9]|[689][0-9])[0-9]{11}/
    // American Express:34, 37 15桁 /3[47][0-9]{13}/
    // JCB:2131, 1800 15桁 | 3528 - 3589 16桁 /(?:2131|1800|35[0-9]{3})[0-9]{11}/
    // China UnionPay:622126 - 622925, 624 - 626, 6282 - 6288 16桁 /62(?:2[0-9]|[4-6][0-9]|8[2-8])[0-9]{12}/
    const regex = /^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5]|2[2-7])[0-9]{14}|6(?:011|4[4-9][0-9]|5[0-9]{2})[0-9]{12}|3(?:0[0-9]|[689][0-9])[0-9]{11}|3[47][0-9]{13}|(?:2131|1800|35[0-9]{3})[0-9]{11}|62(?:2[0-9]|[4-6][0-9]|8[2-8])[0-9]{12})$/;
    if (!regex.test(value)) return '※正しい形式でカード番号を入力してください';
    return '';
  }

  const securityCodeValidation = (value) => {
    const regex = /^[0-9]{3,4}$/;
    if (!regex.test(value)) return '※半角数字3桁または4桁で入力してください';
    return '';
  }

  const refreshData = useCallback(async () => {
    try {
      setLockData(true);
      const site = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/site/search/`, { site_id: siteId })).data;
      setSiteName(site.site_name);
      const mPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/payment/get_method/`, { site_id: siteId, line_id: lineId });
      const memberData = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payment/get_member/`, { site_id: siteId, line_id: lineId })).data;
      console.log(memberData);
      const val = {
        cardNo: '',
        month: '',
        year: '',
        sec: '',
        method: '',
        payTimes: '',
        cardId: '',
        paymentType: '',
      }
      // 決済未登録・決済停止中の場合はカードリストを表示しない
      if ([constClass.PAYMENT_STATUS.UPAY, constClass.PAYMENT_STATUS.CCL].includes(payment_status)) {
        memberData.member.card = [];
      }
      setMember(memberData.member);
      if (memberData.member.card && memberData.member.card.length > 0) {
        val.cardId = memberData.member.card[0].cardId;
      } else if (memberData.member.transaction_card) {
        val.cardId = memberData.member.transaction_id;
      }
      const method_data = (await mPromise).data;
      const temp_methods = [{ value: '1', label: '一回払' }, { value: '2', label: '分割払' }, { value: '3', label: 'ボーナス一括' }, { value: '4', label: 'ボーナス2回払' }, { value: '5', label: 'リボ' }];
      const new_method_list = temp_methods.filter(m => method_data.method_list.includes(Number(m.value))); // method_listに設定された支払方法のみ
      setMethods(new_method_list);
      if (new_method_list.length === 1) { // 支払方法が1個しかない
        val.method = new_method_list[0].value;
      }
      setPayTimesList(method_data.pay_times_list.map(p => ('00' + p).slice(-2))); // pay_times_listに設定された支払回数のみ
      setPaymentTypes(method_data.payment_list);
      if (method_data.payment_list.length > 0) {
        val.paymentType = method_data.payment_list[0];
      }
      setValues(val);
      // 試験用データ
      if (process.env.REACT_APP_ENV !== 'pro') {
        setYears([year, year + 1, year + 2, year + 3, year + 4, year + 5, year + 6, year + 7, year + 8, year + 9, year + 10, 9998, 9999]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLockData(false);
    }
  }, [siteId, lineId, year, payment_status]);

  const checkReady = useCallback(() => {
    return (siteId !== null && siteId !== undefined) && lineId && id && amount;
  }, [siteId, lineId, id, amount]);

  useEffect(() => {
    if (checkReady()) {
      refreshData();
    }
  }, [refreshData, checkReady]);

  const checkInvalid = () => {
    var invalid = false;
    if (values.paymentType === 'credit') { // 決済手段がクレジットカードのとき
      if (!values.cardId) { // 新規カード登録のとき
        if (!values.cardNo || !values.month || !values.year || !values.sec) { // カード情報に不足がある
          invalid = true;
        }
      }
      if (!values.method || (values.method === methodInstallment && !values.payTimes)) { // 支払方法が未選択 または 分割払いで支払回数未選択
        invalid = true;
      }
    } else if (values.paymentType === 'paypay') { // 決済手段がPayPayのとき
      // 入力項目がないため特にエラー無し
    } else { // 決済手段が未選択
      invalid = true;
    }
    if (message.cardNo || message.month || message.year || message.sec || message.method || message.payTimes) { // 入力項目のエラーメッセージがあるとき
      invalid = true;
    }
    return invalid;
  }

  const maskify = (cc, num = 4) => {
    if (num > 0) {
      return cc.slice(0, (num * -1)).replace(/./g, '*') + cc.slice((num * -1));
    } else {
      return cc.replace(/./g, '*');
    }
  }

  const displayCardNo = (card) => {
    return card.cardNo + ' (' + card.expire + ')'
  }

  const displayPaymentType = (p) => {
    var name = '';
    if (p === 'credit') {
      name = 'クレジットカード';
    } else if (p === 'paypay') {
      name = 'PayPay';
    }
    return name;
  }

  const submit = async () => {
    // 初回表示のメッセージを消す
    setPaymentMessage('');
    // 決済手段によって挙動を変更
    if (values.paymentType === 'credit') {
      // 処理完了後
      if (result) {
        // 失敗（再入力）⇒結果を初期化して入力画面に戻る
        if (result.status !== 'success') {
          setResult(null);
        }
        return;
      }
      // 入力画面
      if (!confirm) {
        // 確認画面に進む
        setConfirm(true);
        return;
      }
      // 処理中もしくは入力ミス
      if (lockData || checkInvalid()) {
        return;
      }
      // 確認画面⇒決済処理
      setLockData(true);
      try {
        // 決済用パラメータ作成
        const auth_params = {
          site_id: siteId,
          id,
          amount,
          fee,
          method: values.method,
          payTimes: values.payTimes,
          member_id: member.member_id,
          line_id: lineId
        };
        if (member.actual_id) {
          auth_params.actual_id = member.actual_id;
        }

        // カードIDが無い⇒新規カード登録のためトークン設定
        if (!values.cardId) {
          // 決済トークン取得用URL取得
          const tokenUrl = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payment/get_token_url/`, { site_id: siteId, line_id: lineId })).data;
          console.log(tokenUrl);
          const params = { ...tokenUrl.data };
          params[tokenUrl.input.cardNo] = values.cardNo;
          params[tokenUrl.input.month] = params[tokenUrl.input.month].replace('MM', values.month);
          params[tokenUrl.input.year] = params[tokenUrl.input.year].replace('YYYY', values.year);
          params[tokenUrl.input.year] = params[tokenUrl.input.year].replace('YY', values.year.substring(2, 4));
          params[tokenUrl.input.sec] = values.sec;
          console.log(params);

          //決済トークン取得
          try {
            const token = (await axios({
              method: tokenUrl.method,
              url: tokenUrl.url,
              data: params
            })).data;
            console.log(token);

            // パラメータに追加
            auth_params.token = token.token;
          } catch (e) {
            // console.log(util.inspect(e));
            // console.log(e.response.data ? e.response.data.message : 'エラーが発生しました。カード情報が正しいかご確認ください。');
            setConfirm(false);
            setResult({
              status: 'failure',
              message: e.response.data ? (e.response.data.message + 'カード情報が正しいかご確認の上、再度お試しください。') : 'カード情報が正しいかご確認の上、再度お試しください。'
            });
            throw e;
          }
        } else {
          // カードIDが選択済み⇒カードID設定
          // 会員の場合はカードID、会員未使用の場合は元取引ID
          if (member.actual_id) {
            auth_params.cardId = values.cardId;
          } else if (member.transaction_id) {
            auth_params.originalId = values.cardId;
          }
        }

        // 決済実行
        const res = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payment/get_authorize/`, auth_params)).data;
        console.log(res);
        if (res.status === 'success') {
          if(successFunc) {
            await successFunc();
          }
        }
        setConfirm(false);
        setResult(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLockData(false);
      }
    } else if (values.paymentType === 'paypay') {
      // 処理中もしくは入力ミス
      if (lockData || checkInvalid()) {
        return;
      }
      // 決済処理
      setLockData(true);
      // 送信するデータを作成
      const data = {
        site_id: siteId,
        payment_type: values.paymentType,
        id,
        amount,
        fee,
        member_id: member.member_id,
        line_id: lineId,
        actual_id: member.actual_id,
        successUrl: returnUrl,
        errorUrl: backUrl,
      }
      // backendにPOSTしてリダイレクトさせる
      const form = document.createElement('form');
      form.action = `${process.env.REACT_APP_BACKEND_URL}/payment/redirect_payment/`;
      form.method = 'POST';

      // body に追加
      document.body.append(form);
      for (const i in data) {
        // obj.hasOwnProperty() はオブジェクトのプロトタイプチェーンからプロパティを絞り込むために使用しています
        if (data.hasOwnProperty(i)) {
          const input = document.createElement('input');
          input.setAttribute('type', 'hidden');
          input.setAttribute('name', i);
          input.setAttribute('value', data[i]);
          form.appendChild(input);
        }
      }

      // submit
      form.submit();
    }
  }

  const back = async () => {
    // 不正な画面遷移での戻り処理
    if (!checkReady()) {
      if (backUrl) {
        history.replace(backUrl);
      } else {
        history.replace(location.pathname);
      }
      return;
    }
    // 処理完了後
    if (result) {
      if (result.status === 'success') {
        // 成功（戻る）⇒完了関数実行
        if (returnFunc) {
          setLockData(true);
          const result = await returnFunc();
          if (!result) {
            setLockData(false);
          }
        }
        // 完了URL遷移
        if (returnUrl) {
          history.replace(returnUrl);
        }
        return;
      } else {
        // 失敗（戻る）⇒キャンセル関数実行
        if (backFunc) {
          setLockData(true);
          const result = await backFunc();
          if (!result) {
            setLockData(false);
          }
        }
        // キャンセルURL遷移
        if (backUrl) {
          history.replace(backUrl);
        }
        return;
      }
    }
    // 確認画面
    if (confirm) {
      // （再入力）⇒入力画面に戻る
      setConfirm(false);
      return;
    } else {
      // 入力画面（戻る）⇒キャンセル関数実行
      if (backFunc) {
        setLockData(true);
        const result = await backFunc();
        if (!result) {
          setLockData(false);
        }
      }
      // キャンセルURL遷移
      if (backUrl) {
        history.replace(backUrl);
      }
    }
  }

  const submitCancel = async () => {
    setLockData(true);
    const result = await cancelFunc();
    if (!result) {
      setLockData(false);
    }
  }

  const renderInfo = () => {
    return (
      <React.Fragment>
        {(setting && setting[constClass.SETTING.PAYMENT_MESSAGE]) &&
          <div className="row mx-0 text-left mb-2">
            <div className="col px-0">{Common.getBrString(setting[constClass.SETTING.PAYMENT_MESSAGE])}</div>
          </div>
        }
        {(setting && setting[constClass.SETTING.PAYMENT_MESSAGE2]) &&
          <div className="row mx-0 text-left mb-2">
            <div className="col px-0">{Common.getBrString(setting[constClass.SETTING.PAYMENT_MESSAGE2])}</div>
          </div>
        }
        {amount !== constClass.AUTH_ONLY &&
          <div className="row mx-0 text-left mb-2">
            <div className="col px-0">
              <span>nimocaをご登録の方は月額{setting[constClass.SETTING.MEMBER_AMOUNT]}円となります。<br />
                <Link to={`/${siteId}/?page=`}><b><u>こちら</u></b></Link>からでもnimoca番号をご登録いただけます。</span>
            </div>
          </div>
        }
        {paymentMessage &&
          <div className="row mx-0 text-left mb-2">
            <div className="col px-0 text-danger">{paymentMessage && Common.getBrString(paymentMessage)}</div>
          </div>
        }
        <div className="row mx-0 mt-3 text-center">
          <div className="col-1"></div>
          <div className="col-10 border py-2">
            <div className="row mx-0 text-center">
              <div className="col-7 text-left">月額</div>
              <div className="col-5 text-right">&yen;{monthly_amount.toLocaleString()}</div>
            </div>
          </div>
          <div className="col-1"></div>
        </div>
        <div className="row mx-0 text-center form-group">
          <div className="col-1"></div>
          <div className="col-10 px-0">
            {amount === constClass.AUTH_ONLY && <span>翌月以降のお支払いに反映されます。</span>}
            {/* {amount !== constClass.AUTH_ONLY && <span>nimocaのご登録は<Link to={`/${siteId}/?page=`}>こちら</Link></span> } */}
          </div>
          <div className="col-1"></div>
        </div>
      </React.Fragment>
    )
  }

  const renderPayment = () => {
    return (
      <React.Fragment>
        <div className="row mx-0 text-center mb-1">
          <div className="col">お支払い方法</div>
        </div>
        {(!confirm && paymentTypes.length > 1) &&
          <React.Fragment>
            <div className="col px-0 align-self-center form-group">
              <select className="custom-select w-100 text-center" aria-label="決済手段" id="paymentType" name="paymentType" value={values.paymentType} onChange={handleChange}>
                <option value="">お支払い方法を選択してください</option>
                {paymentTypes.map(p =>
                  <option key={p} value={p}>{displayPaymentType(p)}</option>
                )}
              </select>
            </div>
          </React.Fragment>
        }
        {values.paymentType === 'credit' &&
          <React.Fragment>
            {(member.card && member.card.length > 0 && (!confirm || values.cardId)) &&
              <React.Fragment>
                <div className="row mx-0 text-left">
                  <div className="col px-0">クレジットカード</div>
                </div>
                {!confirm &&
                  <div className="col px-0 align-self-center form-group">
                    <select className="custom-select w-100 text-center" aria-label="カードID" id="cardId" name="cardId" value={values.cardId} onChange={handleChange}>
                      <option value="">新しいカードを登録する</option>
                      {member.card.map(c =>
                        <option key={c.cardId} value={c.cardId}>{displayCardNo(c)}</option>
                      )}
                    </select>
                  </div>
                }
                {confirm &&
                  <div className="col align-self-center form-group">
                    <span>{displayCardNo(member.card.find(c => c.cardId === values.cardId))}</span>
                  </div>
                }
              </React.Fragment>
            }
            {!values.cardId &&
              <React.Fragment>
                <div className="row mx-0 text-left">
                  <div className="col px-0">クレジットカード番号</div>
                </div>
                <div className="row mx-0 text-left">
                  {!confirm &&
                    <div className="col px-0 align-self-center">
                      <input type="number" className="form-control" id="cardNo" name="cardNo" value={values.cardNo} onChange={handleChange} placeholder="(半角数字、ハイフン(-)不要)"></input>
                    </div>
                  }
                  {confirm &&
                    <div className="col align-self-center">
                      <span>{maskify(values.cardNo, 4)}</span>
                    </div>
                  }
                </div>
                <div className="row mx-0 text-left form-group">
                  <div className="col px-0 text-danger font-weight-normal">{message.cardNo}</div>
                </div>
                {!confirm &&
                  <React.Fragment>
                    <div className="row mx-0 text-left">
                      <div className="col px-0">有効期限(月)</div>
                    </div>
                    <div className="row mx-0 text-left">
                      <div className="col px-0 align-self-center">
                        <select className="custom-select w-100 text-center" aria-label="月" id="month" name="month" value={values.month} onChange={handleChange}>
                          <option value="">月</option>
                          {months.map(m =>
                            <option key={m} value={m}>{m}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="row mx-0 text-left form-group">
                      <div className="col px-0 text-danger font-weight-normal">{message.month}</div>
                    </div>
                    <div className="row mx-0 text-left">
                      <div className="col px-0">有効期限(年)</div>
                    </div>
                    <div className="row mx-0 text-left">
                      <div className="col px-0 align-self-center">
                        <select className="custom-select w-100 text-center" aria-label="年" id="year" name="year" value={values.year} onChange={handleChange}>
                          <option value="">年</option>
                          {years.map(y =>
                            <option key={y} value={y}>{y}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="row mx-0 text-left form-group">
                      <div className="col px-0 text-danger font-weight-normal">{message.year}</div>
                    </div>
                  </React.Fragment>
                }
                {confirm &&
                  <React.Fragment>
                    <div className="row mx-0 text-left">
                      <div className="col px-0">有効期限(月/年)</div>
                    </div>
                    <div className="row mx-0 text-left form-group">
                      <div className="col align-self-center">
                        <span>{values.month}/{values.year}</span>
                      </div>
                    </div>
                  </React.Fragment>
                }
                <div className="row mx-0 text-left">
                  <div className="col px-0">セキュリティコード</div>
                </div>
                <div className="row mx-0 text-left">
                  {!confirm &&
                    <div className="col px-0 align-self-center">
                      <input type="number" className="form-control" id="sec" name="sec" value={values.sec} onChange={handleChange} placeholder="(半角数字3桁、または4桁)"></input>
                    </div>
                  }
                  {confirm &&
                    <div className="col align-self-center">
                      <span>{maskify(values.sec, 0)}</span>
                    </div>
                  }
                </div>
                <div className="row mx-0 text-left form-group">
                  <div className="col px-0 text-danger font-weight-normal">{message.sec}</div>
                </div>
              </React.Fragment>
            }
            <div className="row mx-0 text-left">
              <div className="col px-0">支払方法</div>
            </div>
            <div className="row mx-0 text-left">
              {!confirm &&
                <div className="col px-0 align-self-center">
                  <select className="custom-select w-100 text-center" aria-label="支払方法" id="method" name="method" value={values.method} onChange={handleChange}>
                    <option value="">選択してください</option>
                    {methods.map(m => // 対応している支払方法に絞り込み
                      <option key={m.value} value={m.value}>{m.label}</option>
                    )}
                  </select>
                </div>
              }
              {confirm &&
                <div className="col align-self-center">
                  <span>{methods.find(m => m.value === values.method).label}</span>
                </div>
              }
            </div>
            <div className="row mx-0 text-left form-group">
              <div className="col px-0 text-danger font-weight-normal">{message.method}</div>
            </div>
            {values.method === methodInstallment &&
              <React.Fragment>
                <div className="row mx-0 text-left">
                  <div className="col px-0">支払回数</div>
                </div>
                <div className="row mx-0 text-left">
                  {!confirm &&
                    <div className="col px-0 align-self-center">
                      <select className="custom-select w-100 text-center" aria-label="支払回数" id="payTimes" name="payTimes" value={values.payTimes} onChange={handleChange}>
                        <option value="">選択してください</option>
                        {payTimesList.map(p =>
                          <option key={p} value={p}>{Number(p)}</option>
                        )}
                      </select>
                    </div>
                  }
                  {confirm &&
                    <div className="col align-self-center">
                      <span>{Number(values.payTimes)}</span>
                    </div>
                  }
                </div>
                <div className="row mx-0 text-left form-group">
                  <div className="col px-0 text-danger font-weight-normal">{message.payTimes}</div>
                </div>
              </React.Fragment>
            }
          </React.Fragment>
        }
        {values.paymentType === 'paypay' &&
          <div className="row mx-0 bg-white px-1 pt-1 pb-2">
            <div className="col">
              決済手続きのため、PayPayサイトへ遷移します。
            </div>
          </div>
        }
      </React.Fragment>
    )
  }

  const renderResult = () => {
    return (
      <React.Fragment>
        <div className="row mx-0 mb-3 text-center">
          <div className={`col text-${result.status === 'success' ? 'success' : 'danger'}`}>
            <h5>お支払い{amount === constClass.AUTH_ONLY ? '情報登録' : '手続き'}{result.status === 'success' ? 'が完了しました。' : 'に失敗しました。'}</h5>
          </div>
        </div>
        {result.status === 'success' &&
          <React.Fragment>
            <div className="row mx-0 text-left">
              <div className="col px-0">クレジットカード番号</div>
            </div>
            <div className="row mx-0 text-left form-group">
              <div className="col align-self-center">
                <span>{maskify(values.cardId ? member.card.find(c => c.cardId === values.cardId).cardNo : values.cardNo, 4)}</span>
              </div>
            </div>
            <div className="row mx-0 text-left">
              <div className="col px-0">支払方法</div>
            </div>
            <div className="row mx-0 text-left form-group">
              <div className="col align-self-center">
                <span>{methods.find(m => m.value === values.method).label}</span>
              </div>
            </div>
            {values.method === methodInstallment &&
              <React.Fragment>
                <div className="row mx-0 text-left">
                  <div className="col px-0">支払回数</div>
                </div>
                <div className="row mx-0 text-left form-group">
                  <div className="col align-self-center">
                    <span>{Number(values.payTimes)}</span>
                  </div>
                </div>
              </React.Fragment>
            }
          </React.Fragment>
        }
        {result.status !== 'success' &&
          <div className="row mx-0 text-left">
            <div className="col px-0">{result.message}</div>
          </div>
        }
      </React.Fragment>
    )
  }

  const backButtonName = () => {
    var name = setting[constClass.SETTING.PAYMENT_BACK];
    if (confirm) {
      name = '再入力';
    } else if (result && result.status === 'success') {
      name = `${siteName}をはじめる`;
    } else if (change) {
      name = '戻る';
    }
    return name;
  }

  return (
    <div className="row mx-0">
      <div className="col px-0-env">
        <div className="row mx-0 bg-white px-3 pt-2 pb-1">
          {checkReady() &&
            <div className="col bg-white py-1 px-1 align-self-center">
              {renderInfo()}
              {!result && renderPayment()}
              {result && renderResult()}
            </div>
          }
          {!checkReady() &&
            <div className="col bg-white py-1 px-1 align-self-center">
              正しい画面遷移がされませんでした。<br />
              お手数ですが、再度やり直してください。<br />
            </div>
          }
        </div>
        <div className="row mx-0 bg-white px-3 pt-1 pb-2">
          {(checkReady() && (!result || result.status !== 'success')) &&
            <div className="col">
              <button
                disabled={lockData || checkInvalid()}
                className="btn-lg btn-active w-100 py-2"
                onClick={() => { submit() }}>
                {confirm ? '確定' : (result ? '再入力' : '次へ')}
              </button>
            </div>
          }
        </div>
        <div className="row mx-0 bg-white px-3 pt-1 pb-2">
          <div className="col">
            <button
              disabled={lockData}
              className={`${(result && result.status === 'success') ? 'btn-lg btn-active' : 'btn btn-link'} w-100 py-2`}
              onClick={() => { back() }}>
              {backButtonName()}
            </button>
          </div>
        </div>
        {(checkReady() && [constClass.PAYMENT_STATUS.REG, constClass.PAYMENT_STATUS.REREG].includes(payment_status) && !result && !confirm) &&
          <div className="row mx-0 bg-white px-3 pt-1 pb-2">
            <div className="col">
              <button
                disabled={lockData}
                className={`${(result && result.status === 'success') ? 'btn-lg btn-active' : 'btn btn-link'} w-100 py-2`}
                onClick={() => { submitCancel() }}>
                お支払いを停止する
              </button>
            </div>
          </div>
        }
        {(checkReady() && change && [constClass.PAYMENT_STATUS.UPAY, constClass.PAYMENT_STATUS.ERR, constClass.PAYMENT_STATUS.CCL].includes(payment_status) && !result && !confirm) &&
          <div className="row mx-0 bg-white px-3 pt-1 pb-2">
            <div className="col">
              <button
                disabled={lockData}
                className={`${(result && result.status === 'success') ? 'btn-lg btn-active' : 'btn btn-link'} w-100 py-2`}
                onClick={() => { submitCancel() }}>
                退会する
              </button>
            </div>
          </div>
        }
      </div>
      {lockData && <Loading />}
    </div>
  );
}

export default Payment;
export const JobCdName = {
  AUTH: '仮売上', //仮売上（オーソリ）
  SALES: '実売上', //実売上
  PEND: '保留', //保留
  VOID: '取消', //取消
  ERR: 'エラー' //エラー
};