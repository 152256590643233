import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
// import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
// import { useHistory, useParams, generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router';
// import { Link } from 'react-router-dom';

const CouponCode = (props) => {
  // const { user } = props;
  const { id, siteId } = useParams();
  const [couponData, setCouponData] = useState(null);
  const [couponCodeData, setCouponCodeData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const [condition, setCondition] = useState({ couponMonth: moment().format('YYYYMM') });
  const [searchFlag, setSearchFlag] = useState(true);
  const [errMsg, setErrMsg] = useState({ couponMonth: '' });
  const { addToast } = useToasts();
  const history = useHistory();

  const [values, setValues] = useState({
    coupon_code_id: null,
    coupon_month: moment().format('YYYYMM'),
    coupon_code: '',
  });

  /* RESETボタン押下 */
  const handleResetClick = useCallback(() => {
    setValues({
      coupon_code_id: null,
      coupon_month: condition.couponMonth,
      coupon_code: '',
    });
  }, [condition.couponMonth]);

  const refreshData = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    if (searchFlag && errMsg.couponMonth === '') {
      try {
        const couponData = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/coupon/${siteId}/${id}`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })).data;
        setCouponData(couponData);
        const params = {
          site_id: siteId,
          coupon_id: id
        };
        // 利用年月
        if (condition.couponMonth && errMsg.couponMonth === '') {
          params.coupon_month = condition.couponMonth;
        }
        const couponCodeData = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/coupon_code/count/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })).data;
        setCouponCodeData(couponCodeData);
        handleResetClick();
      } catch (err) {
        console.log(err);
      }
    }
    setSearchFlag(false);
  }, [siteId, id, errMsg, condition, searchFlag, handleResetClick]);

  const handleChangeCondition = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setCondition({ ...condition, [name]: value });
    setErrMsg({ ...errMsg, [name]: validator(name, value) });
  }

  const validator = (name, value) => {
    switch (name) {
      case 'couponMonth':
        return monthValidation(value);
      default:
        return '';
    }
  }

  const monthValidation = (value) => {
    const regex = /^[0-9]{6}$/;
    if (!regex.test(value)) return '※正しい形式で年月を入力してください';
    return '';
  }

  // /* 編集ボタン押下 */
  // const handleUpdateClick = (data) => {
  //   var temp = data;

  //   setValues(temp);
  // }

  // /* DB更新 */
  // const handleRegistClick = async (data) => {
  //   const jwt = localStorage.getItem('jwt');
  //   if (!data.coupon_code) {
  //     window.alert('クーポンコードを入力してください。');
  //     return;
  //   }

  //   var params = data;
  //   params.ins_name = user.userId;
  //   params.upd_name = user.userId;
  //   params.site_id = siteId;
  //   params.coupon_id = id;

  //   let res;
  //   try {
  //     setLockData(true);
  //     if (params.coupon_code_id === null || params.coupon_code_id === "") {
  //       res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/coupon_code/`, params, {
  //         headers: {
  //           Authorization: `Bearer ${jwt}`
  //         }
  //       });
  //     } else {

  //       res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/coupon_code/${params.coupon_code_id}`, params, {
  //         headers: {
  //           Authorization: `Bearer ${jwt}`
  //         }
  //       });
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     if (err.response.data !== null) {
  //       addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
  //     } else {
  //       addToast(err.response, { appearance: 'error', autoDismiss: true });
  //     }
  //   } finally {
  //     if (!res) {
  //     } else if (res.data.error) {
  //       addToast(res.data.message, { appearance: 'error', autoDismiss: true });
  //     } else {
  //       addToast('登録しました。', { appearance: 'success', autoDismiss: true });
  //       setSearchFlag(true);
  //       await refreshData();
  //     }
  //     setLockData(false);
  //     handleResetClick();
  //   }
  // }
  // /* DB削除 */
  // const handleDeleteClick = async (data) => {
  //   const jwt = localStorage.getItem('jwt');

  //   if (!window.confirm(`クーポンコード[${data.coupon_code_id}:${data.coupon_code}]を削除します。よろしいですか？`)) {
  //     return;
  //   }
  //   const params = {
  //     site_id: siteId,
  //     coupon_id: id,
  //     coupon_month: data.coupon_month,
  //     upd_date: data.upd_date
  //   };
  //   let res;
  //   try {
  //     setLockData(true);
  //     res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/coupon_code/delete/${data.coupon_code_id}`, params, {
  //       headers: {
  //         Authorization: `Bearer ${jwt}`,
  //       }
  //     });
  //   } catch (err) {
  //     if (err.response.data !== null) {
  //       addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
  //     } else {
  //       addToast(err.response, { appearance: 'error', autoDismiss: true });
  //     }
  //   } finally {
  //     if (res.data.error) {
  //       addToast(res.data.message, { appearance: 'error', autoDismiss: true });
  //     } else {
  //       addToast('削除しました。', { appearance: 'success', autoDismiss: true });
  //       setSearchFlag(true);
  //       await refreshData();
  //     }
  //     setLockData(false);
  //     handleResetClick();
  //   }
  // }
  /* ファイルアップロード */
  const handleUploadClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.uploadfile) {
      window.alert('ファイルを選択してください。');
      return;
    }

    let res;
    try {
      setLockData(true);
      const form = new FormData();
      if (data.uploadfiletype) form.append("uploadfile", data.uploadfile);
      form.append("site_id", siteId);
      form.append("coupon_id", id);
      form.append("coupon_month", data.coupon_month);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/coupon_code/upload/`, form, {
        headers: {
          Authorization: `Bearer ${jwt}`,
          'content-type': 'multipart/form-data',
        }
      });

    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        setSearchFlag(true);
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }

  /* */
  // const renderUpdateButton = (data) => {
  //   return (
  //     <button
  //       className={`btn btn-primary mx-1`}
  //       disabled={data.customer_id}
  //       onClick={() => handleUpdateClick(data)}>
  //       編集
  //     </button>
  //   )
  // }

  // /* */
  // const renderRegistButton = (data) => {
  //   return (
  //     <button type="button"
  //       disabled={lockData || data.coupon_code === ''}
  //       className={`btn btn-primary mx-1 w-50`}
  //       onClick={() => handleRegistClick(data)}>
  //       登録
  //     </button>
  //   )
  // }

  // /* */
  // const renderDeleteButton = (data) => {
  //   return (
  //     <button type="button"
  //       disabled={lockData || data.coupon_code_id === null}
  //       className={`btn btn-primary mx-1 w-50`}
  //       onClick={() => handleDeleteClick(data)}>
  //       削除
  //     </button>
  //   )
  // }

  // /* */
  // const renderResetButton = () => {
  //   return (
  //     <button type="button"
  //       disabled={false}
  //       className={`btn btn-primary mx-1`}
  //       onClick={() => handleResetClick()}>
  //       リセット
  //     </button>
  //   )
  // }

  /* */
  const renderUploadButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || !data.uploadfile}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleUploadClick(data)}>
        アップロード
      </button>
    )
  }

  // const handleChange = (e) => {
  //   const target = e.target;
  //   const value = target.type === "checkbox" ? target.checked : target.value;
  //   const name = target.name;
  //   setValues({ ...values, [name]: value });
  // }

  const handleChangeFile = (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      setValues({
        ...values,
        uploadfile: value,
        uploadfiletype: value.type,
        deletefile: values.couponCodes,
        uploadfiledata: URL.createObjectURL(value),
      });
    }
    reader.readAsDataURL(value);
  }

  const handleDeleteFile = () => {
    setValues({ ...values, uploadfile: null, uploadfiletype: null, deletefile: values.couponCodes });
  }

  useEffect(() => {
    refreshData();
  }, [refreshData]); //

  // const renderInput = () => {
  //   return (
  //     <div className="mx-1">
  //       <div className="row mb-3">
  //         <div className="col-2 text-center align-self-center">
  //           <span>クーポンコードID</span>
  //         </div>
  //         <div className="col-2 text-center align-self-center">
  //           <input type="text" className="form-control" name="coupon_code_id" defaultValue={values.coupon_code_id} placeholder="新規" readOnly />
  //         </div>
  //         <div className="col-2 text-center align-self-center">
  //           <span>利用年月</span>
  //         </div>
  //         <div className="col-2 text-center align-self-center">
  //           <input type="text" className="form-control" name="coupon_month" value={values.coupon_month} placeholder="新規" readOnly />
  //         </div>
  //       </div>
  //       <div className="row mb-3">
  //         <div className="col-2 text-center align-self-center">
  //           <span>クーポンコード</span>
  //         </div>
  //         <div className="col-4 text-center align-self-center">
  //           <input type="text" className="form-control" name="coupon_code" value={values.coupon_code} onChange={handleChange} placeholder="クーポンコードを入力してください" />
  //         </div>
  //       </div>
  //       <div className="row mb-2 pb-4">
  //         <div className="col-3 text-center">
  //           {renderRegistButton(values)}
  //         </div>
  //         <div className="col-3 text-center">
  //           {renderDeleteButton(values)}
  //         </div>
  //         <div className="col-3 text-center">
  //           {renderResetButton(values)}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  const renderFileUpload = () => {
    return (
      <div className="mx-1 border">
        <div className="row my-2">
          <div className="col-2 text-center align-self-center">
            <span>利用年月</span>
          </div>
          <div className="col-2 text-center align-self-center">
            <input type="text" className="form-control" name="coupon_month" value={values.coupon_month} placeholder="新規" readOnly />
          </div>
          <div className="col-1 text-center align-self-center">
            <span>CSV</span>
          </div>
          <div className="col-4 align-self-center">
            {!(values.uploadfile) && <input type="file" className="form-control-file" name="couponCodes" id="couponCodes" value="" onChange={handleChangeFile} onClick={e => (e.target.value = null)} />}
            {values.uploadfile && <input type="button" className="form-controll" name="deletefile" id="deletefile" onClick={handleDeleteFile} value="ファイル削除" />}
            {values.uploadfile && values.uploadfile.name}
          </div>
          <div className="col-3 text-center">
            {renderUploadButton(values)}
          </div>
        </div>
      </div>
    );
  }

  // const renderList = () => {
  //   return (
  //     <div className="row mb-3 p-0">
  //       <div className="col-12 p-0">
  //         <table className="table table-bordered table-striped">
  //           <thead className={`table-info`}>
  //             <tr>
  //               <td className="text-center align-middle">
  //                 ID
  //               </td>
  //               <td className="text-center align-middle">
  //                 利用年月
  //               </td>
  //               <td className="text-center align-middle">
  //                 クーポンコード
  //               </td>
  //               <td className="text-center align-middle">
  //                 使用ユーザ
  //               </td>
  //               <td className="text-center align-middle">
  //                 使用日時
  //               </td>
  //               <td className="text-center align-middle">
  //                 処理
  //               </td>
  //             </tr>
  //           </thead>
  //           <tbody>
  //             {couponCodeData.map((data, idx) => (
  //               <tr key={data.coupon_code_id}>
  //                 {/* ID */}
  //                 <td className="text-center align-middle">
  //                   {data.coupon_code_id}
  //                 </td>
  //                 {/* 利用年月 */}
  //                 <td className="text-center align-middle">
  //                   {data.coupon_month}
  //                 </td>
  //                 {/* クーポンコード */}
  //                 <td className="text-center align-middle">
  //                   {data.coupon_code}
  //                 </td>
  //                 {/* 使用ユーザ */}
  //                 <td className="text-center align-middle">
  //                   {data.t_customer ? <Link to={`${generatePath(`${props.match.path}`, { siteId })}customer/${data.customer_id}`}>{data.t_customer.mail_address}</Link> : ''}
  //                 </td>
  //                 {/* 使用日時 */}
  //                 <td className="text-center align-middle">
  //                   {data.use_date ? moment(data.use_date).format("YYYY/MM/DD HH:mm:ss") : ''}
  //                 </td>
  //                 {/* 処理 */}
  //                 <td className="text-center align-middle text-nowrap">
  //                   {renderUpdateButton(data)}
  //                 </td>
  //               </tr>
  //             ))
  //             }
  //           </tbody>
  //         </table>
  //       </div>
  //     </div>
  //   );
  // }

  const renderCount = () => {
    return (
      <div className="row my-3 p-0">
        <div className="col-12 p-0">
          {couponCodeData.map(c =>
            <div key={c.coupon_code_count} className="row mx-3">
              <div className="col-1"></div>
              <div className="col-5">
                <div className="row h-100">
                  <div className="col-auto text-center align-self-center">
                    <h5>登録総数</h5>
                  </div>
                  <div className="col text-left align-self-center">
                    <h5>{c.coupon_code_count ? c.coupon_code_count : 0}件</h5>
                  </div>
                </div>
              </div>
              <div className="col-5">
                <div className="row h-100">
                  <div className="col-auto text-center align-self-center">
                    <h5>空きコード数</h5>
                  </div>
                  <div className="col text-left align-self-center">
                    <h5>{c.unused_code_count ? c.unused_code_count : 0}件</h5>
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      {couponData &&
        <div className="row mx-0 my-2 px-1-env">
          <div className="col">
            <div className="row my-1">
              <div className="col-auto text-left">
                <button type="button"
                  className={`btn btn-secondary mx-1 w-100`}
                  onClick={() => history.goBack()}>
                  戻る
                </button>
              </div>
              <div className="col">
                <form onSubmit={(e) => { setSearchFlag(true); e.preventDefault() }} >
                  <div className="row">
                    <div className="col-3">
                      <div className="row h-100">
                        <div className="col-auto text-center align-self-center">
                          <span>クーポンID</span>
                        </div>
                        <div className="col text-left align-self-center">
                          {couponData.coupon_id}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="row h-100">
                        <div className="col-auto text-center align-self-center">
                          <span>クーポン名</span>
                        </div>
                        <div className="col text-left align-self-center">
                          {couponData.coupon_name}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="row">
                        <div className="col-auto text-center align-self-center">
                          <span>利用年月</span>
                        </div>
                        <div className="col text-center">
                          <input className={`form-control ${errMsg.couponMonth !== '' ? 'is-invalid' : ''}`} type="text" id="couponMonth" name="couponMonth" value={condition.couponMonth} onChange={handleChangeCondition}></input>
                        </div>
                      </div>
                    </div>
                    <div className="col-2 text-center">
                      <button type="button"
                        className={`btn btn-primary mx-1 w-100`}
                        disabled={errMsg.couponMonth !== ''}
                        onClick={() => setSearchFlag(true)}>
                        検索</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      }
      {couponCodeData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {/* {couponCodeData !== null && (renderList())} */}
      {couponCodeData !== null && (renderCount())}
      {/* {renderInput()} */}
      {renderFileUpload()}
    </div>
  )
}

export default CouponCode;